import moment from "moment";
import {USER_ID} from "./Auth";
const env = process.env.REACT_APP_ENV;

export let DEV_ENV_VARIABLES;

if (env === "development") {
    DEV_ENV_VARIABLES = {
        dev: {
            dev_name: 'dev',
            link: process.env.REACT_APP_API_URL,
            ip: process.env.REACT_APP_API_URL_IP,
            apiDomain: 'dev-api',
            domain: 'dev.'
        },
        dev1: {
            dev_name: 'dev1',
            link: process.env.REACT_APP_API_URL1,
            ip: process.env.REACT_APP_API_URL1_IP,
            apiDomain: 'dev1-api',
            domain: 'dev1.'
        },
        dev2: {
            dev_name: 'dev2',
            link: process.env.REACT_APP_API_URL2,
            ip: process.env.REACT_APP_API_URL2_IP,
            apiDomain: 'dev2-api',
            domain: 'dev2.'
        },
        dev3: {
            dev_name: 'dev3',
            link: process.env.REACT_APP_API_URL3,
            ip: process.env.REACT_APP_API_URL3_IP,
            apiDomain: 'dev3-api',
            domain: 'dev3.'
        },
        dev4: {
            dev_name: 'dev4',
            link: process.env.REACT_APP_API_URL4,
            ip: process.env.REACT_APP_API_URL4_IP,
            apiDomain: 'dev4-api',
            domain: 'dev4.'
        },
        dev5: {
            dev_name: 'dev5',
            link: process.env.REACT_APP_API_URL5,
            ip: process.env.REACT_APP_API_URL5_IP,
            apiDomain: 'dev5-api',
            domain: 'dev5.'
        }
    };
} else {
    DEV_ENV_VARIABLES = {
        dev: {
            dev_name: 'dev',
            link: process.env.REACT_APP_API_URL,
            ip: process.env.REACT_APP_API_URL_IP,
            apiDomain: 'api',
            domain: ''
        },
    };
}

let ip, apiDomain, domain;

export const getActualDevEnv = (env) => {
    const devEnvObject = DEV_ENV_VARIABLES[env];

    ip = devEnvObject.ip;
    apiDomain = devEnvObject.apiDomain;
    domain = devEnvObject.domain;

    return {ip, apiDomain, domain}
}

export const SIDEBAR_LINKS = {
    'affiliate_network': [
        {
            name: 'Affiliate Network Offers',
            title: 'Affiliate Network Offers',
            link: '/affiliate-network-offers/',
            status: true
        },
        {
            name: 'Program Management',
            title: 'Program Management',
            link: '/program-management/',
            status: true
        },
        {
            name: 'Postback Setup',
            title: 'Postback Setup',
            link: '/postback-setup/',
            status: true
        },
        {
            name: 'Postback Log',
            title: 'Postback Log',
            link: '/postback-log/',
            status: true
        },
    ],
    'voluum': [
        {
            name: 'Changed campaigns',
            title: 'Changed campaigns',
            link: '/changed-campaigns-list/',
            status: true
        },
        {
            name: 'Close Voluum Offer',
            title: 'Close Voluum Offer',
            link: '/change-voluum-offer/',
            status: true
        }
    ],
    'yieldkit': [
        {
            name: 'Account settings',
            title: 'Yieldkit Account settings',
            link: '/yieldkit-account-settings/',
            status: true
        },
        {
            name: 'Yieldkit Offers - BULK ONLY',
            title: 'Yieldkit Offers - BULK ONLY',
            link: '/yieldkit-offers/',
            status: true
        },
        {
            name: 'Yieldkit CPC offers - BULK ONLY',
            title: 'Yieldkit CPC offers - BULK ONLY',
            link: '/yieldkit-offers-cpc/',
            status: true
        },
        {
            name: 'Yieldkit Status Checker',
            title: 'Yieldkit Status Checker',
            link: '/yieldkit-status-checker/',
            status: true
        },
        {
            name: 'Yieldkit Sales Report',
            title: 'Yieldkit Sales Report',
            link: `https://api.optim8.io/yieldkit/reports/sales`,
            status: true
        }
    ],
    'alerts': [
        {
            name: 'Alerts Settings',
            title: 'Voluum Alerts Settings',
            link: '/voluum-alerts-settings/',
            status: true
        },
        {
            name: 'Alerts',
            title: 'Alerts',
            link: '/alerts/',
            status: true
        }
    ],
    'discovery_tool': [
        {
            name: 'New offers upload',
            title: 'New offers upload',
            link: `https://#DOMAIN#dsctool.com/form/68c4e975630c8491f104cce7943de74f`,
            status: true
        },
        {
            name: 'Campaigns',
            title: 'Discovery tool campaigns',
            link: '/discovery-tool-campaigns/',
            status: true
        },
        {
            name: 'Discovery Offers',
            title: 'Discovery tool offers',
            link: '/discovery-tool-offers/',
            status: true
        },
        {
            name: 'Discovery Details',
            title: 'Discovery tools details',
            link: '/discovery-tool-details/',
            status: true
        },
    ],
    // 'kelkoo': [
    //     {
    //         name: 'Report',
    //         title: 'Kelkoo Report',
    //         link: '/kelkoo-reports/',
    //         status: true
    //     }
    // ],
    'reports': [],
    'monitoring': [
        {
            name: 'Grafana',
            title: 'Grafana',
            link: '/grafana/',
            status: false
        }
    ]
}

export const SIDEBAR_LINKS_DOCUMENTATION =  [
    {
        title: 'User Manual',
        link: 'https://docs.google.com/document/d/1LDFUdGPkEttBT7gPlfjyy0W4rasKRwTFbF8eilOv8ws/edit#heading=h.l9amrefml00n',
        status: true
    },
    {
        title: 'Presentation',
        link: 'https://docs.google.com/presentation/d/17a4LVzDEpOepIJl0GuuN2wXRubIOCHXx/edit#slide=id.g204816b89bb_0_1',
        status: true
    }
]

export const NOT_SIDEBAR_LINKS = [
    {
        title: 'New User',
        link: '/new-user/',
    },
    {
        title: 'User Profile',
        link: '/user-profile/',
    },
    {
        title: 'Changed Campaigns Details',
        link: '/changed-campaigns-details/',
    },
    {
        name: 'Yieldkit Status Checker',
        title: 'Yieldkit Status Checker',
        link: '/yieldkit-status-checker/',
        status: true
    },
    {
        name: 'YK2 Status Checker',
        title: 'YK2 Status Checker',
        link: '/yk2-checker/',
        status: true
    },
    {
        name: 'Admin Links',
        title: 'Admin Links',
        link: '/admin-links/',
        status: true
    },
    {
        name: 'Approval rate checker',
        title: "Approval rate checker",
        link: '/approval-rate-checker/',
        status: true
    }
]

export const ADMIN_LINKS = [
    {
        title: "YK2 Export",
        link: `https://#API_DOMAIN#.optim8.io/export-oponia-offers`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/export-oponia-offers`,
        externalType: true
    },
    {
        title: "YK2 offers checker",
        link: '/yk2-checker/',
        linkToCopy: `https://#DOMAIN#optim8.io/yk2-checker/`,
        externalType: false
    },
    {
        title: "YK2 sales report",
        link: `https://#API_DOMAIN#.optim8.io/oponia/reports/sales`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/oponia/reports/sales`,
        externalType: true
    },
    {
        title: "Approval rate checker",
        link: '/approval-rate-checker/',
        linkToCopy: `https://#DOMAIN#optim8.io/approval-rate-checker/`,
        externalType: false
    },
    {
        title: "YK Export",
        link: `https://#API_DOMAIN#.optim8.io/advertiser-data/pbhmbynekmwn`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/advertiser-data/pbhmbynekmwn`,
        externalType: true
    },
    {
        title: "YK Export (No Cloudflare)",
        link: `http://#IP#/advertiser-data/pbhmbynekmwn`,
        linkToCopy: `http://#IP#/advertiser-data/pbhmbynekmwn`,
        externalType: true
    },
    {
        title: "Discovery tool - Offers upload form",
        link: `https://#DOMAIN#dsctool.com/form/68c4e975630c8491f104cce7943de74f`,
        linkToCopy: `https://#DOMAIN#dsctool.com/form/68c4e975630c8491f104cce7943de74f`,
        externalType: true
    },
    {
        title: "Noctemque Export",
        link: `https://#API_DOMAIN#.optim8.io/noctemque-export`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/noctemque-export`,
        externalType: true
    },
    {
        title: "Noctemque Export (No Cloudflare)",
        link: `http://#IP#/noctemque-export`,
        linkToCopy: `http://#IP#/noctemque-export`,
        externalType: true
    },
    {
        title: "Noctemque sales report",
        link: `https://#API_DOMAIN#.optim8.io/noctemque/reports/sales`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/noctemque/reports/sales`,
        externalType: true
    },
    {
        title: "Webgains Export (No Cloudflare)",
        link: `http://#IP#/webgains-export`,
        linkToCopy: `http://#IP#/webgains-export`,
        externalType: true
    },
    {
        title: "Tradedoubler Export (No Cloudflare)",
        link: `http://#IP#/tradedoubler-export`,
        linkToCopy: `http://#IP#/tradedoubler-export`,
        externalType: true
    },
    {
        title: "JoinGekko Export",
        link: `https://#API_DOMAIN#.optim8.io/joingekko-export`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/joingekko-export`,
        externalType: true
    },
    {
        title: "Takeads export (No Cloudflare)",
        link: `http://#IP#/takeads-export`,
        linkToCopy: `http://#IP#/takeads-export`,
        externalType: true
    },
    {
        title: "Brandreward Export",
        link: `https://#API_DOMAIN#.optim8.io/brandreward-export`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/brandreward-export`,
        externalType: true
    },
    {
        title: "Ecomnia Export",
        link: `https://#API_DOMAIN#.optim8.io/ecomnia-export`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/ecomnia-export`,
        externalType: true
    },
    {
        title: "Shoplooks Export",
        link: `https://#API_DOMAIN#.optim8.io/shoplooks-export`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/shoplooks-export`,
        externalType: true
    },
    {
        title: "Linkbux Export",
        link: `https://#API_DOMAIN#.optim8.io/linkbux-export`,
        linkToCopy: `https://#API_DOMAIN#.optim8.io/linkbux-export`,
        externalType: true
    }
    
]

export const ADMIN_LINKS_SIDEBAR_BLOCKS = ['kelkoo', 'discovery_tool'];

export const TABS_CONTENT = {
    'infected_sites': [
        'Scanned sites',
        'Infected files',
        'Possible infected files',
        'Scanned logs per yesterday'
    ]
}

export const HOMEPAGE_CONTENT = [
    {
        'name': 'Changed Campaigns',
        'project': 'Voluum',
        'description': 'This page will provide you with the information regarding the status of you campaigns in Voluum.',
    },
    {
        'name': 'Account settings',
        'project': 'Yieldkit',
        'description': 'This section features a table containing the configurations for your Yieldkit account.',
    },
    {
        'name': 'Yieldkit offers',
        'project': 'Yieldkit',
        'description': 'In this section, you have the ability to download all the information regarding Yieldkit offers. Utilizing filters for each column allows you to selectively view and download the offers that are most relevant to you at the moment.',
    },
    {
        'name': 'Yieldkit Status Checker',
        'project': 'Yieldkit',
        'description': 'In this section you can check whether your Yieldkit links are active or deactivated.',
    },
    {
        'name': 'Alerts settings',
        'project': 'Voluum',
        'description': 'A table presenting all the alerts that are accessible to you. You can edit or add them if necessary.',
    },
    {
        'name': 'Alerts',
        'project': 'Voluum & Yieldkit',
        'description': 'Within this section, you can access and download all data related to Voluum and Yieldkit alerts. By applying filters to each column, you can easily browse and download the offers that are most applicable to you at the moment.',
    }
]

export const PAGINATION_PAGE_SIZE_ARR = [
    {project: 10},
    {project: 50},
    {project: 100}
]

export const SHOW_VOLUUM_COLUMNS = ['alertName', 'alertDescription', 'active'];
export const VOLUUM_ALERTS_ORDER = {
    'Description': [
        'active',
        'alertName',
        'alertDescription'
    ],
    'affNetworks': [
        'affNetworksOption',
        'affNetworks',
        'tags'
    ],
    'period': [
        'period'
    ],
    'others': [
        'conversions',
        'cost',
        'roi',
        'offersTrafficPercentDown',
        'revenue',
        'visitsPeriod',
        'visits'
    ],
    'changedCampaign': [
        'alertConfig'
    ]
}

const year = moment().year();
export const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = moment(cellValue).format('DD/MM/YYYY');
        const dateParts = dateAsString.split("/");
        const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    inRangeInclusive: true,
    maxValidYear: year,
    inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
};

export const OFFER_TYPE = 'offer';
export const PATH_TYPE = 'path';
export const FLOWS_TYPE = 'flow';
export const CAMPAIGNS_TYPE = 'campaign';
export const REACTIVATED_OFFER_TYPE = 'reactivated offer';
export const CLOSED_CAMPAIGNS_LIST_TABS = [OFFER_TYPE, PATH_TYPE, FLOWS_TYPE, CAMPAIGNS_TYPE];
export const CLOSED_CAMPAIGNS_TABS = [PATH_TYPE, FLOWS_TYPE, CAMPAIGNS_TYPE];

export const FILE_TYPE_CSV = 'csv';
export const FILE_TYPE_XLS = 'xls';
export const FILE_TYPE_XLSX = 'xlsx';
export const FILE_TYPES = [FILE_TYPE_CSV, FILE_TYPE_XLS, FILE_TYPE_XLSX];

export const UPLOADER_TYPE_LINKS = 'links';
export const UPLOADER_TYPE_OFFERS = 'offers';
export const UPLOADER_TYPE_LINKS_FRONT_ONLY = 'links_front_only';

export const UPLOADER_TYPE_TOP_OFFERS = 'top_offers';

export const MOBILE_FILTER_TYPE = {
    id: 'mobile-device',
    name: 'Device and Mobile Carrier'
};
export const OS_BROWSER_FILTER_TYPE = {
    id: 'os-browser',
    name: 'OS and Browser'
};
export const OS_CONNECTION_THREE_FILTER_TYPE = {
    id: 'os-connection',
    name: 'OS and Connection'
};
export const ISP_FILTER_TYPE = {
    id: 'isp-connection',
    name: 'ISP and Connection'
};
export const GRATER_TYPE = {
    id: '>',
    name: 'greater than',
    hintText: 'of more than'
};
export const LESS_TYPE = {
    id: '<',
    name: 'less than',
    hintText: 'of less than'
};
export const EQUAL_TYPE = {
    id: '=',
    name: 'equal',
    hintText: 'equals to'
};
export const VOLUUM_REPORTS_FILTER_LIST = [MOBILE_FILTER_TYPE, OS_BROWSER_FILTER_TYPE, OS_CONNECTION_THREE_FILTER_TYPE, ISP_FILTER_TYPE];
export const VOLUUM_REPORTS_OPERATORS_LIST = [GRATER_TYPE, LESS_TYPE, EQUAL_TYPE];
export const VOLUUM_REPORTS_MAIN_FILTERS = ['roi', 'cr', 'cost'];

export const OPONIA_VISIBLE_COLUMNS = ['offer_name', 'url', 'offer_status'];

export const FILTERS_TYPE_VOLUUM_REPORT = 'voluum_report';

export const DISCOVERY_TOOL_TYPE_OFFERS = 'offers';
export const DISCOVERY_TOOL_TYPE_CAMPAIGNS = 'campaigns';
export const DISCOVERY_TOOL_TYPE_DETAILS = 'details';
export const DISCOVERY_TOOL_STATUSES_TO_CHANGE = ['in_discovery', 'new'];

export const AWIN_STATUS_ACTIVE = 'active';
export const AWIN_STATUS_HIDDEN = 'hidden';
export const AWIN_STATUS_NOTFOUND = 'not found';

export const DISCOVERY_TOOL_CAMPAIGN_STATUSES = {
    active: "active",
    deactivated: "deactivated",
}
