import { ADMIN_LINKS, getActualDevEnv } from "../constants";

export const updateAdminLinks = (env) => {
    const { ip, apiDomain, domain } = getActualDevEnv(env);

    return ADMIN_LINKS.map(el => {
        const updatedLink = el.link.replace('#API_DOMAIN#', apiDomain)
            .replace('#DOMAIN#', domain)
            .replace('#IP#', ip);
        const updatedLinkToCopy = el.linkToCopy.replace('#API_DOMAIN#', apiDomain)
            .replace('#DOMAIN#', domain)
            .replace('#IP#', ip);
        return {
            ...el,
            link: updatedLink,
            linkToCopy: updatedLinkToCopy
        };
    });
};
