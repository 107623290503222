import authApiInstance from "./new-axios-instance";


const resetPassword = async (newPassword, resetPassToken, email) => {
    const res = await authApiInstance.post('/reset', {
        "token": resetPassToken,
        "email": email,
        "password": newPassword,
    })
    return res;
}

export default resetPassword;