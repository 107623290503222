import Notiflix from "notiflix";

const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem('auth_token')}`,
}

export const getPostbacks = async (params, token) => {
    return await fetch(`${localStorage.getItem("apiUrl")}api/postbacks/get`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(params)
    })
}


export const getTemplates = async (token) => {
    return await fetch(`${localStorage.getItem("apiUrl")}api/templates`, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    })
}

export const getPostbackLogs = async (page, size, filters, token) => {
    const queryParams = new URLSearchParams({
        page: page,
        size: size,
        ...filters
    });

    const url = `${localStorage.getItem("apiUrl")}api/postbacks/logs?${queryParams}`;

    return await fetch(url, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
    });
}




export const deletePostback = async (id, token) => {
    return await fetch(`${localStorage.getItem("apiUrl")}api/postbacks/${id}`, {
        method: 'DELETE',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    })
        .then(res => res.json())
        .then(res => {
            if (!res.error) {
                return res
            }
            return Promise.reject(res);
        })
        .catch(e => console.error(e.error));
}

export const createPostback = async (values, token) => {

    let data = {
        "postback_name": values["postback_name"],
        "postback_status": values["postback_status"],
        "service_credential_id": values["service_credential_id"],
        "voluum_tracking_domain": values["voluum_tracking_domain"],
        "template_id": values["template_id"],
        "et": values["et"] || "",
        "start_click_date": values["start_click_date"] || ""
    }

    return await fetch(`${localStorage.getItem("apiUrl")}api/postbacks/create`, {
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    })
        .then(res => {
            return res.json()
        })
        .then(res => {
            if (res.message) {
                return  Notiflix.Notify.success(res.message);
            }
            return Promise.reject(res);
        })
        .catch(e => console.error(e.error));
}

export const updatePostback = async (values, token) => {

    let data = {
        "postback_name": values["postback_name"],
        "postback_status": values["postback_status"],
        "service_credential_id": values["service_credential_id"],
        "voluum_tracking_domain": values["voluum_tracking_domain"],
        "template_id": values["postback_template_id"],
        "et": values["et"] || "",
        "start_click_date": values["start_click_date"]
    }

    return await fetch(`${localStorage.getItem("apiUrl")}api/postbacks/${values.id}`, {
        method: 'PUT',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    })
        .then(res => {
            return res.json()
        })
        .then(res => {
            if (res) {
                return Notiflix.Notify.success(res.message);

            }
            return Promise.reject(res);
        })
        .catch(e => console.error(e.error));
}