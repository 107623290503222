import React from 'react';
import {Field, Form, Formik} from "formik";
import CustomFileUploader from "../../CustomFileUploader";
import CustomTextarea from "../inputs/CustomTextarea";
import YkStatusCheckerPopup from "../../popups/YkStatusCheckerPopup";

import "../forms-styles.scss";
import './textarea-checker.scss';

const OffersCheckerForm = ({
                               type,
                               initialValues,
                               currentValues,
                               emailNotification,
                               setEmailNotification,
                               validate,
                               onSubmit,
                               isLoading,
                               fileLinksList,
                               setFileLinksList,
                               listLengthError,
                               formError,
                               setFormError,
                               fileText
                           }) => {
    return (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({errors, touched, values}) => (
            <Form>
                <CustomTextarea
                    name={'linksList'}
                    validate={validate}
                    value={fileLinksList.length !== 0 ? '' : values.linksList}
                    errors={errors.linksList}
                    touched={touched.linksList}
                    arrLength={fileLinksList.length}
                />
                {listLengthError ? <span className='yk-checker-form-error'>Please use file uploading if you need more than 250 links to check.</span> : null}
                <p className='yk-checker-text'>Or upload a file (max 1 MB) here:</p>
                <p className='yk-checker-text'>{fileText}</p>
                <Field>
                    {() => (
                        <CustomFileUploader
                            type={type}
                            setFileLinksList={setFileLinksList}
                            currentValues={currentValues}
                            setFormError={setFormError}
                        />
                    )}
                </Field>

                <YkStatusCheckerPopup
                    open={emailNotification || false}
                    handleClose={setEmailNotification}
                />
                <button className='blue-btn form-submit' type="submit" disabled={isLoading || formError}>Submit</button>
            </Form>
        )}
    </Formik>
)};

export default OffersCheckerForm;