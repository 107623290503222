import Notiflix from "notiflix";

export const showNotification = (type, text) => {

    switch(type) {
        case "success": {
            return Notiflix.Notify.success(text)
        }

        case "warning": {
            return  Notiflix.Notify.warning(text)
        }

        default: {
            return  Notiflix.Notify.failure(text)
        }
    }
}