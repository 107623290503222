import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import delayedExecution from "../../helpers/delayed-execution";
import {setErrorSubmitted, setLoadingState, setSuccessfullyState} from "../../redux/dataUploader/dataSlice";
import FormsLayout from '../../components/forms/FormsLayout';
import ForgotPassword from "../../components/forms/ResetPassword";
import Loader from "../../components/loaders/Loader";
import resetPasswordService from "../../services/reset-password";
import ErrorPopup from "../../components/popups/ErrorPopup";

const ResetPassword = () => {
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        newPassword: '',
        duplicateNewPassword: ''
    });
    const [currentValues, setCurrentValues] = useState({
        newPassword: '',
        duplicateNewPassword: ''
    });
    const [unconfirmedPasswordError, setUnconfirmedPasswordError] = useState(false);
    const [mismatchPasswordError, setMismatchPasswordError] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const errorSubmitted = useSelector((state) => state.dataUploader.errorSubmitted);
    const isLoadingState = useSelector((state) => state.dataUploader.isLoading);
    const dispatch = useDispatch();
    const {search} = useLocation();
    const navigate = useNavigate();
    const isSuccessfully = useSelector((state) => state.dataUploader.isSuccessfully);

    const reparationSearchParams = () => {
        const params = [];

        if (search) {
            const brokenString = search.split('&');

            const tokenString = brokenString[0].split("=");
            const token = tokenString[1];

            const emailString = brokenString[1].split("=");
            const email = emailString[1];
            params.push(token);
            params.push(email);
        }

        return params
    }

    const checkOnResetPassword = async () => {
        setLoading(true);
        const filteredSearchParams = reparationSearchParams();

        if (filteredSearchParams.length === 0) {
            console.error("No parameters to reset password!");
            navigate("/login");
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        checkOnResetPassword();
    }, [search])

    const changeNewPassword = (value) => {
        setCurrentValues(previousState => {
            return {...previousState, newPassword: value}
        });
        return validatePassword(value)
    }

    const changeDuplicatePassword = (value) => {
        setCurrentValues(previousState => {
            return {...previousState, duplicateNewPassword: value}
        });
        return validatePassword(value)
    }

    const validatePassword = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 8 || value.length > 16) {
            error = 'Password must be between 8 and 16 characters';
        }
        return error;
    }

    const resetPassword = async (values) => {
        const {newPassword, duplicateNewPassword} = values;

        try {
            dispatch(setLoadingState(true));
            if (newPassword !== duplicateNewPassword) {
                setMismatchPasswordError(true);
                throw new Error('Password mismatch!');
            }
            const filteredSearchParams = reparationSearchParams();
            await resetPasswordService(values.newPassword, filteredSearchParams[0], filteredSearchParams[1]);
            dispatch(setSuccessfullyState(true));
            dispatch(setLoadingState(false));
            delayedExecution(
                () => {
                    navigate("/login");
                    dispatch(setSuccessfullyState(false));
                }
            )


        } catch (err) {
            console.error(err)
            dispatch(setLoadingState(false));
            dispatch(setErrorSubmitted(true));
        }
    }

    const formErrorText = () => {
        if (unconfirmedPasswordError) {
            return 'Unconfirmed password!';
        } else if (mismatchPasswordError) {
            return 'Password mismatch!';
        } else {
            return 'Unknown error, please contact support';
        }
    }

    if (isLoading) {
        return (
            <div className='loader-wrap'>
                <Loader/>
            </div>
        )
    }

    if (errorSubmitted) {
        setTimeout(() => {
            dispatch(setErrorSubmitted(false));
            setUnconfirmedPasswordError(false);
            setMismatchPasswordError(false);
        }, 2000)
    }

    return (
        <>
            <ErrorPopup
                popupText={formErrorText}
                popupIsActive={errorSubmitted}
            />
            <FormsLayout formTitle='Reset Password' isLoading={isLoadingState}>
                <ForgotPassword
                    initialValues={initialValues}
                    currentValues={currentValues}
                    validatePassword={validatePassword}
                    onSubmit={resetPassword}
                    isLoading={isLoadingState}
                    isSuccessfully={isSuccessfully}
                    changeNewPassword={changeNewPassword}
                    changeDuplicatePassword={changeDuplicatePassword}
                />
                {formErrorText && <p>{formErrorText}</p>}
            </FormsLayout>
        </>
    );
}

export default ResetPassword;