import React, {useState, useEffect, useMemo} from 'react';
import CustomTooltip from "../../CustomTooltip";
import Table from "../Table";
import ShowMoreBtn from "../../buttons/ShowMoreBtn";
import {filterParams} from "../../../constants";
import extractCapInformation from "../../../helpers/extract-cap-information";

const PreTableClosedCampaigns = ({title, getTabTitle, rowData, onDataTableChanged, listStatus = false, getTableRef}) => {
    const [columnDefs, setColumnDefs] = useState([]);

    useEffect(() => {
        getTabTitle(title);
        if (rowData && rowData.length > 0) {
            const colArr = Object.entries(rowData[0]).map(([field, value]) => {
                let columnDef = {
                    field: field,
                    headerName: field.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
                    filter: !isNaN(value) && value !== '' ? 'agNumberColumnFilter' : true,
                    minWidth: 150,
                    suppressSizeToFit: false,
                    wrapText: true,
                    tooltipField: field,
                    tooltipComponent: CustomTooltip,
                    hide: rowData.every(row => row[field] === '' || row[field] === null),
                };

                if (field === 'voluumAccountId' || field === 'pathId' || field === 'campaignId' || field === 'id' || field === 'flowId') {
                    columnDef = {
                        ...columnDef,
                        hide: true,
                    };
                } else if (field === 'voluum_name') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 120,
                        maxWidth: 130
                    };
                } else if (field === 'affiliate_network') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 100,
                        maxWidth: 120
                    };
                } else if (field === 'changed_reason') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 250,
                        maxWidth: 250,
                        cellRenderer: params => {
                            const {value} = params;
                            return  value[0] === '|' ? value.slice(1) : value;
                        }
                    };
                } else if (field === 'type' || field === 'id' || field === 'action' || field === 'status_for_action') {
                    columnDef = {
                        ...columnDef,
                        hide: true,
                        minWidth: 80,
                        maxWidth: 80
                    };
                } else if (field === 'offerName') {
                    columnDef = {
                        ...columnDef,
                    };
                } else if (field === 'workspaceName') {
                    columnDef = {
                        ...columnDef,
                        maxWidth: 115
                    };
                } else if (field === 'created_at' || field === 'updated_at' || field === 'deactivation_datetime' || field === 'deactivation_datetime' || field === 'last_path_auto_change_datetime' || field === 'last_flow_auto_change_datetime' || field === 'last_campaign_auto_change_datetime') {
                    columnDef = {
                        ...columnDef,
                        minWidth: 170,
                        filter: 'agDateColumnFilter',
                        filterParams: filterParams,
                        cellRenderer: (params) => {
                            const {value} = params;
                            return value.replace('.000Z', '')
                        }
                    };
                } else if (field === 'offer_status' || field === 'path_status' || field === 'path_status_in_the_flow') {
                    columnDef = {
                        ...columnDef,
                        cellRenderer: (params) => {
                            const {value} = params;
                            return (
                                <span className={`status-cell${value === 'active' || value === 'activated' ? ' active-cell' : value === 'deactivated' ? ' deactivated-cell' : ' empty-cell'}`}>
                                    {value}
                                </span>
                            )
                        }
                    };
                } else if (field === 'campaign_status_in_traffic_source') {
                    columnDef = {
                        ...columnDef,
                        cellRenderer: (params) => {
                            const {value} = params;
                            return (
                                <span className={`status-cell${value === 'activated' ? ' active-cell' : value === 'deactivated' ? ' deactivated-cell' : ' empty-cell'}`}>
                                    {value === 'deactivated' ? 'to be deactivated' : value}
                                </span>
                            )
                        }
                    };
                }
                return columnDef;
            });

            if (listStatus) {
                colArr.push({
                    field: 'clicks_num',
                    headerName: 'Click cap',
                    cellRenderer: params => {
                        return extractCapInformation(params.data, 'clicks_num');
                    },
                    filter: !isNaN('clicks_num') && 'clicks_num' !== '' ? 'agNumberColumnFilter' : true,
                    sortable: true,
                    minWidth: 100,
                    tooltipField: 'clicks_num',
                    tooltipComponent: CustomTooltip,
                    hide: rowData.every(row => row['clicks_num'] === '' || row['clicks_num'] === null)
                },{
                    field: 'cap_limit',
                    headerName: 'Daily cap',
                    cellRenderer: params => {
                        return extractCapInformation(params.data, 'cap_limit');
                    },
                    filter: !isNaN('cap_limit') && 'cap_limit' !== '' ? 'agNumberColumnFilter' : true,
                    sortable: true,
                    minWidth: 100,
                    tooltipField: 'cap_limit',
                    tooltipComponent: CustomTooltip,
                    hide: rowData.every(row => row['cap_limit'] === '' || row['cap_limit'] === null)
                },{
                    field: 'cap_action',
                    headerName: 'Status',
                    cellRenderer: params => {
                        const value = extractCapInformation(params.data, 'cap_action');
                        return (
                            <span className={`status-cell${value && value === 'activated' ? ' active-cell' : value && value.includes('deactivated') ? ' deactivated-cell' : value && value.includes('reactivated') ? ' active-cell' : ' empty-cell'}`}>
                                    {value}
                                </span>
                        )
                    },
                    filter: true,
                    sortable: true,
                    wrapText: true,
                    minWidth: 250,
                    tooltipField: 'cap_action',
                    tooltipComponent: CustomTooltip,
                    hide: rowData.every(row => row['cap_action'] === '' || row['cap_action'] === null)
                },{
                    field: 'ShowMore',
                    headerName: '',
                    cellRenderer: params => <ShowMoreBtn params={params}/>,
                    resizable: false,
                    editable: false,
                    sortable: false,
                    minWidth: 55,
                    maxWidth: 55,
                    pinned: 'right'
                })
            }
            setColumnDefs(colArr);
        }
    }, [rowData]);

    const onGridReady = params => {
        const allColumnIds = [];
        params.columnApi.getColumns().forEach((column) => {
            if (column.getId() !== 'id') allColumnIds.push(column.getId());
        });
        params.columnApi.autoSizeAllColumns(allColumnIds);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        autoHeight: true,
        // flex: 1
    }));

    return (
        <Table
            rowData={rowData}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            onFilterChanged={onDataTableChanged ? onDataTableChanged : null}
            onSortChanged={onDataTableChanged ? onDataTableChanged : null}
            onGridReady={onGridReady}
            headerClassCol={true}
            getTableRef={getTableRef}
        />
    );
};


export default PreTableClosedCampaigns;