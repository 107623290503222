import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import Loader from "../../loaders/Loader";
import {USER_PROJECT, USER_ROLE} from "../../../constants/Auth";
import createAccountData from '../../../data-collectors/create-yieldkit-account';
import updateAccountsConfigTable from "../../../data-collectors/update-yieldkit-account";
import {checkYieldkitAccountService} from "../../../services/yieldkit";

import "react-tagsinput/react-tagsinput.css";
import './add-item.scss'
import '../../forms/UpdateConfigForm/update-config-form.scss'

const NewItemPopup = ({
                          initialValues,
                          remarksList,
                          setInitialValues,
                          closePopup,
                          isPopupType,
                          accountDetails,
                          setAccountDetails,
                          affiliateNetworks,
                      }) => {
    const dispatch = useDispatch();
    const projectName = localStorage.getItem(USER_PROJECT);
    const role = localStorage.getItem(USER_ROLE);
    const currentItemData = useSelector((state) => state.dataUploader.voluumAlertItem);
    const [fieldsError, setFieldsError] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        const currentItemDataLength = Object.keys(currentItemData).length;
        if (currentItemDataLength) {
            setInitialValues(currentItemData);
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }, [currentItemData, setInitialValues])

    const validateOther = value => {
        const regex = /^[a-zA-Z0-9 !@#$%^&*()_+\-='"\\|,.?]*$/
        if (value === '') {
            return 'Required field';
        } else if (!regex.test(value)) {
            return 'No special symbols';
        }
    }

    const validateSpecialSymbols = value => {
        if (value === 'Rule') {
            return;
        }
        const regex = /^[a-zA-Z0-9 !@#$%^&*()_+\-='"\\|,.?]*$/
        if (!regex.test(value)) {
            return 'No special symbols';
        }
    }

    const validateNumber = value => {
        if (value === '') {
            return 'Required field';
        } else if (!/^(|-?\d+)$/.test(value)) {
            return 'Numbers only';
        }
    }

    const onSubmit = async (values) => {
        if (values['affNetworksOption'] === 'all') {
            values['affNetworks'] = null;
        }

        try {
            setFieldsError(false);
            setFormLoading(true);
            const data = {
                'apiKey': values['api_key'],
                'apiSecret': values['api_secret'],
                'siteId': values['site_id']
            }
            const result = await checkYieldkitAccountService(data);
            if(JSON.parse(result.data.data)) {
                let newValuesObj = {};
                Object.entries(values).forEach(el => {
                    if (el[1] === '') {
                        newValuesObj[el[0]] = null;
                    } else {
                        newValuesObj[el[0]] = el[1];
                    }

                    if (el[0] === 'status' && el[1] === '') {
                        newValuesObj[el[0]] = false;
                    }

                    if (el[0] === 'remark') {
                        if (role === 'admin') {
                            if (!remarksList.includes(el[1])) {
                                newValuesObj[el[0]] = remarksList[0];
                            }
                        } else {
                            if (!remarksList.includes(el[1])) {
                                newValuesObj[el[0]] = projectName;
                            }
                        }
                    }

                    if (el[0] === 'id') {
                        newValuesObj[el[0]] = +el[1];
                    }

                    if (el[0] === 'affiliate_network_id') {
                        if(!el[1]|| el[1] === '') {
                            newValuesObj[el[0]] = affiliateNetworks[0].id;
                        } else {
                            newValuesObj[el[0]] = +el[1];
                        }
                    }
                })

                if (isPopupType === 'updating') {
                    if(newValuesObj.hasOwnProperty('affiliate_network')) {
                        delete newValuesObj['affiliate_network'];
                        await updateAccountsConfigTable(setFormLoading, setFormSuccess, setFormError, dispatch, newValuesObj, accountDetails, setAccountDetails);
                    } else {
                        await updateAccountsConfigTable(setFormLoading, setFormSuccess, setFormError, dispatch, newValuesObj, accountDetails, setAccountDetails);
                    }
                } else if (isPopupType === 'creating') {
                    await createAccountData(setFormLoading, setFormSuccess, setFormError, dispatch, newValuesObj, accountDetails, setAccountDetails);
                }
            }
            setFormLoading(false);
        } catch (error) {
            setFieldsError(true);
            setFormLoading(false);
        }
    }

    return (
        <div className='add-item-popup-container'>
            <div className="add-item-popup-block">
                <div className='form-wrap'>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={onSubmit}>
                        {({errors, touched}) => (
                            <Form>
                                <div className="title-wrap">
                                    <h1>
                                        {isPopupType === 'updating' ? 'Update' : 'Create new account'}
                                    </h1>
                                    <div className="close-btn" onClick={closePopup}>
                                        <img src={`${process.env.PUBLIC_URL}/images/close-btn.svg`} alt="close popup"/>
                                    </div>
                                </div>
                                {
                                    isLoading ?
                                        <Loader/>
                                        :
                                        <>
                                            <div className="form-flex-container">
                                                {Object.keys(initialValues).map(inputItem => {
                                                    if (inputItem === 'id'
                                                        || inputItem === 'voluum_affiliate_network'
                                                        || inputItem === 'affiliate_network'
                                                        || inputItem === 'deleted_at'
                                                    ) return;
                                                    if (inputItem === 'remark') {
                                                        return (
                                                            role === 'admin' ?
                                                                (
                                                                    <div
                                                                        className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                        key={inputItem}>
                                                                        <label htmlFor={inputItem}
                                                                               className="toggle-label">{inputItem}</label>
                                                                        <Field as="select" name={inputItem}>
                                                                            {remarksList.map((option) => {
                                                                                return (
                                                                                    <option key={option}
                                                                                            value={option}>{option}</option>
                                                                                )
                                                                            })}
                                                                        </Field>
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div
                                                                        className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                        key={inputItem}>
                                                                        <label htmlFor={inputItem}
                                                                               className='disabled-label'>{inputItem}</label>
                                                                        <Field
                                                                            id={inputItem}
                                                                            name={inputItem}
                                                                            disabled
                                                                            type='text'
                                                                            validate={validateOther}
                                                                        />
                                                                        {errors[inputItem] && touched[inputItem] ? <div
                                                                            className='form-error'>{errors[inputItem]}</div> : null}
                                                                    </div>
                                                                )
                                                        )
                                                    }
                                                    if (inputItem === 'affiliate_network_id') {
                                                        return (
                                                                    <div
                                                                        className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                        key={inputItem}>
                                                                        <label htmlFor={inputItem} className="toggle-label">{inputItem}</label>
                                                                        <Field as="select" name={inputItem}  className='item-uppercase'>
                                                                            {affiliateNetworks.map(({id, name}) => {
                                                                                return (
                                                                                    <option key={id} value={id} className='item-uppercase'>{name}</option>
                                                                                )
                                                                            })}
                                                                        </Field>
                                                                    </div>
                                                        )
                                                    }
                                                    if (inputItem === 'status') {
                                                        return (
                                                            <div
                                                                className={`input-block without-config-item-block checkbox-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                key={inputItem}>
                                                                <Field type="checkbox" className="toggle-button"
                                                                       name={inputItem}/>
                                                                <label htmlFor={inputItem}
                                                                       className="toggle-label">{inputItem}</label>
                                                            </div>
                                                        )
                                                    }
                                                    if (inputItem === 'voluum_account_id') {
                                                        return (
                                                            <div className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                 key={inputItem}>
                                                                <label htmlFor={inputItem}>{inputItem}</label>
                                                                <Field
                                                                    id={inputItem}
                                                                    name={inputItem}
                                                                    type='number'
                                                                    validate={validateNumber}
                                                                />
                                                                {errors[inputItem] && touched[inputItem] ? <div
                                                                    className='form-error'>{errors[inputItem]}</div> : null}
                                                            </div>
                                                        )
                                                    }

                                                    if (inputItem === 'voluum_affiliate_network_id') {
                                                        return (
                                                            <div className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                 key={inputItem}>
                                                                <label htmlFor={inputItem}>{inputItem}</label>
                                                                <Field
                                                                    id={inputItem}
                                                                    name={inputItem}
                                                                    validate={validateSpecialSymbols}
                                                                />
                                                                {errors[inputItem] && touched[inputItem] ? <div
                                                                    className='form-error'>{errors[inputItem]}</div> : null}
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <div className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                             key={inputItem}>
                                                            <label htmlFor={inputItem}>{inputItem}</label>
                                                            <Field
                                                                id={inputItem}
                                                                name={inputItem}
                                                                type='text'
                                                                validate={validateOther}
                                                            />
                                                            {errors[inputItem] && touched[inputItem] ? <div
                                                                className='form-error'>{errors[inputItem]}</div> : null}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="config-btns-block">
                                                <div className="discard-btn" onClick={closePopup}>Discard</div>
                                                <button className='update-btn'
                                                    type="submit">{isPopupType === 'updating' ? 'Update' : 'Create'}</button>
                                            </div>
                                        </>
                                }
                            </Form>
                        )}
                    </Formik>
                    {formLoading ? <div className="config-btns-loader-block"><Loader/></div> : null}
                    {fieldsError ?
                        <div className="config-btns-message-block"><p className='new-user-error'>Please provide valid tokens</p>
                        </div> : ''}
                    {formError ?
                        <div className="config-btns-message-block"><p className='new-user-error'>Some error occurred</p>
                        </div> : ''}
                    {formSuccess ? <div className="config-btns-message-block"><p className='new-user-success'>Account
                        is {isPopupType === 'updating' ? 'updated' : 'created'}</p></div> : ''}
                </div>
            </div>
        </div>
    )
}

export default NewItemPopup;
