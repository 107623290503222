import {logOut} from "../redux/dataUploader/dataSlice";
import {getClosedCampaignsService} from "../services/voluum";

const GetClosedCampaignsData = async (setLoading, setError, dispatch, setData, setChangedData, type, id, dateRange) => {
    setLoading(true);

    try {
        let start, end;
        if(dateRange[0] && dateRange[1]) {
            start = `${dateRange[0].getFullYear()}-${(dateRange[0].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[0].getDate().toString().padStart(2, '0')}`;
            end = `${dateRange[1].getFullYear()}-${(dateRange[1].getMonth() + 1).toString().padStart(2, '0')}-${dateRange[1].getDate().toString().padStart(2, '0')}`;
        } else {
            start = dateRange[0];
            end = dateRange[1];
        }

        const results = await getClosedCampaignsService({id: id, type: type, start: start, end: end});
        if(results['data'].error) {
            setError(true);
        } else {
            setData(prevState => ({
                ...prevState,
                [type]: results['data'].data
            }));

            setChangedData(prevState => ({
                ...prevState,
                [type]: results['data'].data
            }));
        }
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(true);
        console.error('Error!', error);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default GetClosedCampaignsData;