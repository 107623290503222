import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Table from "../Table";
import UpdateItemBtn from "../../buttons/UpdateItemBtn";
import NewItemPopupWithConfig from "../../popups/NewItemPopupWithConfig";
import NewItemPopupWithoutConfig from "../../popups/NewItemPopupWithoutConfig";
import {itemToUpdate} from "../../../redux/dataUploader/dataSlice";
import {SHOW_VOLUUM_COLUMNS} from "../../../constants";
import {USER_PROJECT, USER_ROLE} from "../../../constants/Auth";
import DeleteItemBtn from "../../buttons/DeleteItemBtn";
import PostbackPopup from "../../popups/PostbackPopupWithoutConfig.js";

const PreTableWithPopups = ({
                         rowData,
                         setNewDataStatus,
                         columnDefs,
                         setColumnDefs,
                         addPopupStatus,
                         setAddPopupStatus,
                         initialValues,
                         setInitialValues,
                         configData,
                         modifiedColumnsData,
                         onCountedNames,
                         addPopupWithConfigData,
                         isPopupType,
                         setPopupType,
                         additionalDefCol,
                         remarksList,
                         accountDetails,
                         setAccountDetails,
                         getTableRef,
                         affiliateNetworks,
                         isDeleteButton,
                         deletePostback,
                         anAccounts,
                        setIsTriggerUpdate,
}) => {
    const dispatch = useDispatch();
    const role = localStorage.getItem(USER_ROLE);
    const projectName = localStorage.getItem(USER_PROJECT);
    const itemStatus = useSelector(state => state.dataUploader.voluumAlertItemStatus);

    useEffect(() => {
        let columns = [];
        if (rowData && rowData.length > 0) {
            columns = Object.keys(rowData[0])

            const colArr = modifiedColumnsData(columns);

            let newColArr;
            if(addPopupWithConfigData) {
                newColArr = colArr.map(el => {
                    if(role === 'admin') {
                        return ![...SHOW_VOLUUM_COLUMNS, 'method'].includes(el.field) ? {...el, hide: true} : el.type === 'number' ? {...el, comparator: (valueA, valueB) => valueA - valueB} : el.field === 'method' ? {...el, maxWidth: 330} : el;
                    } else {
                        return !SHOW_VOLUUM_COLUMNS.includes(el.field) ? {...el, hide: true} : el.type === 'number' ? {...el, comparator: (valueA, valueB) => valueA - valueB} : el;
                    }
                })
            } else {
                    newColArr = colArr.map(el => {
                        return el.field === 'voluum_account_id' ? {...el, hide: true} : el;
                    })
            }

            if (newColArr) {
                if (isDeleteButton) {
                    newColArr.push(
                        {
                            field: 'Edit',
                            headerName: '',
                            cellRenderer: (params) => {
                                return (
                                    <div className='btns-container'>
                                        <UpdateItemBtn onClick={() => openUpdatePopup(params)} newItem={false} />
                                    </div>
                                );
                            },
                            resizable: false,
                            editable: false,
                            minWidth: 70,
                            maxWidth: 110,
                            suppressSizeToFit: true
                        },
                    )

                } else if(!addPopupWithConfigData && (remarksList.includes(projectName) || role === 'admin')) {
                    newColArr.push(
                        {
                            field: 'Edit',
                            headerName: '',
                            cellRenderer: (params) => {
                                return (
                                    <div className='btns-container'>
                                        <UpdateItemBtn onClick={() => openUpdatePopup(params)} newItem={false} />
                                    </div>
                                );
                            },
                            resizable: false,
                            editable: false,
                            minWidth: 70,
                            maxWidth: 70,
                            suppressSizeToFit: true
                        }
                    )
                } else if(remarksList) {
                    if(remarksList.includes(projectName)) {
                        newColArr.push({
                            field: 'Edit',
                            headerName: '',
                            cellRenderer: (params) => {
                                return (
                                    <div className='btns-container'>
                                        <UpdateItemBtn onClick={() => openUpdatePopup(params)} newItem={false} />
                                    </div>
                                );
                            },
                            resizable: false,
                            editable: false,
                            minWidth: 70,
                            maxWidth: 70,
                            suppressSizeToFit: true
                        })
                    }
                }  else {
                    newColArr.push({
                        field: 'Edit',
                        headerName: '',
                        cellRenderer: (params) => {
                            return (
                                <div className='btns-container'>
                                    <UpdateItemBtn onClick={() => openUpdatePopup(params)} newItem={false} />
                                </div>
                            );
                        },
                        resizable: false,
                        editable: false,
                        minWidth: 70,
                        maxWidth: 70,
                        suppressSizeToFit: true
                    })
                }
            }

            setColumnDefs(newColArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData])

    const onGridReady = params => {
        params.api.sizeColumnsToFit();
    };

    const openUpdatePopup = params => {
        setPopupType('updating')
        const {voluum_account_id, ...other} = params.data;
        if (params) dispatch(itemToUpdate(other));
        setAddPopupStatus(true);
    }

    const closePopup = () => {
        dispatch(itemToUpdate({}));
        setAddPopupStatus(false);
        if(itemStatus) setNewDataStatus(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        autoHeight: true,
    }));

    return (
        <>
            {rowData.length > 0 ?
                <Table
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={additionalDefCol ? {...defaultColDef, ...additionalDefCol} : defaultColDef}
                    onGridReady={onGridReady}
                    getTableRef={getTableRef}
                /> :
                <h3 className='no-data-title'>You need to have an account in order to see the information here.</h3>
            }
            {addPopupStatus?
                addPopupWithConfigData ?
                    <NewItemPopupWithConfig
                        columns={columnDefs}
                        configArr={configData}
                        initialValues={initialValues}
                        closePopup={closePopup}
                        onCountedNames={onCountedNames}
                    />
                    :
                    <NewItemPopupWithoutConfig
                        initialValues={initialValues}
                        setInitialValues={setInitialValues}
                        accountDetails={accountDetails}
                        setAccountDetails={setAccountDetails}
                        remarksList={remarksList}
                        closePopup={closePopup}
                        isPopupType={isPopupType}
                        affiliateNetworks={affiliateNetworks}
                        anAccounts={anAccounts}
                        setIsTriggerUpdate={setIsTriggerUpdate}
                    />
                : null
            }
        </>
    );
};

export default PreTableWithPopups;
