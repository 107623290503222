import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { campaignsDownloadExcelById, getCampaignById } from "../../services/change-campaigns";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./changed-campaign-details.scss"
import MessageSendingData from "../../components/popups/MessageSendingData";
import Loader from "../../components/loaders/Loader";
import GoBackBtn from "../../components/buttons/GoBackBtn";

const ChangedCampaignsDetails = () => {
    const { selectedANAccount, id, startDate, endDate, voluumOfferName } = useParams();
    const [campaignData, setCampaignData] = useState({
        campaigns: [],
        flows: [],
        paths: []
    });
    const [columnDefs, setColumnDefs] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [noData, setNoData] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [confirmationPopupText, setConfirmationPopupText] = useState("");

    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        getCampaignsData();
    }, []);

    useEffect(() => {
        const data = currentTab === 0 ? campaignData.campaigns :
            currentTab === 1 ? campaignData.flows :
                campaignData.paths;

        if (data.length > 0) {
            const keys = Object.keys(data[0]);
            const columns = keys.map(key => ({
                headerName: key.charAt(0).toUpperCase() + key.slice(1),
                field: key,
                resizable: true,
                cellRendererFramework: (params) => (
                    <span onClick={() => copyToClipboard(params.value)}>
                    {params.value}
                </span>
                )
            }));
            setColumnDefs(columns);
        } else {
            setColumnDefs([]);
        }
    }, [campaignData, currentTab]);

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            // Optionally, show a message or handle the UI feedback
            console.log('Copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const getCampaignsData = async () => {
        setIsLoading(true);
        setIsError(false);
        setNoData(false);
        try {
            const data = await getCampaignById(selectedANAccount, id, token, startDate, endDate);
            if (data.data.campaigns.length === 0 && data.data.flows.length === 0 && data.data.paths.length === 0) {
                setNoData(true);
                setConfirmationPopupText("No data for this account");
                setShowConfirmationPopup(true);
            } else {
                setCampaignData(data.data);
            }
        } catch (error) {
            setIsError(true);
            setConfirmationPopupText("An error occurred while fetching the data.");
            setShowConfirmationPopup(true);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadExcelById = async () => {
        setIsLoading(true);
        try {
            const data = await campaignsDownloadExcelById(selectedANAccount, id, startDate, endDate, token);

            if (data.status === "success") {
                setConfirmationPopupText(data.message);
                setShowConfirmationPopup(true);
            } else {
                setConfirmationPopupText('An error occurred while downloading the Excel file.');
                setShowConfirmationPopup(true);
            }
        } catch (err) {
            console.error(err);
            setConfirmationPopupText('An error occurred while downloading the Excel file.');
            setShowConfirmationPopup(true);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <div className='loading-wrap'>
            <Loader/>
        </div>
    }

    if (isError) {
        return (
            <div className="changed-campaigns-details">
                <div className="error-container">
                    <h1 className="error-message">No data for this offer</h1>
                    {showConfirmationPopup && (
                        <MessageSendingData
                            messageText={confirmationPopupText}
                            setClosePopup={() => setShowConfirmationPopup(false)}
                        />
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="changed-campaigns-details">
            <div className="changed-campaigns-details__heading">
                <h3>Voluum offer name: <span>{voluumOfferName}</span></h3>
                <h3>Offer id: <span>{id}</span></h3>
            </div>
            <div className={"buttons-container"}>
                <button className={`blue-btn`} onClick={handleDownloadExcelById}>
                    Download Excel
                </button>
            </div>
            <Tabs selectedIndex={currentTab} onSelect={(index) => setCurrentTab(index)}>
                <TabList className="tab-list">
                    <Tab className={`tab ${currentTab === 0 ? 'active' : ''}`}>Campaigns</Tab>
                    <Tab className={`tab ${currentTab === 1 ? 'active' : ''}`}>Flows</Tab>
                    <Tab className={`tab ${currentTab === 2 ? 'active' : ''}`}>Paths</Tab>
                </TabList>

                <TabPanel>
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={campaignData.campaigns}
                            columnDefs={columnDefs}
                            defaultColDef={{ flex: 1, minWidth: 100, resizable: true, sortable: true }}
                            pagination={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={campaignData.flows}
                            columnDefs={columnDefs}
                            defaultColDef={{ flex: 1, minWidth: 100, resizable: true, sortable: true }}
                            pagination={true}
                            resizable={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={campaignData.paths}
                            columnDefs={columnDefs}
                            defaultColDef={{ flex: 1, minWidth: 100, resizable: true, sortable: true }}
                            pagination={true}
                            resizable={true}
                        />
                    </div>
                </TabPanel>
            </Tabs>
            {showConfirmationPopup && (
                <MessageSendingData
                    messageText={confirmationPopupText}
                    setClosePopup={() => setShowConfirmationPopup(false)}
                />
            )}
        </div>
    );
};

export default ChangedCampaignsDetails;
