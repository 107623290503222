import "./modal.scss"
import CreateNewItemBtn from "../buttons/CreateNewItemBtn";
import DiscardButton from "../buttons/DiscardButton";
import {useEffect} from "react";


const modal = ({closeModal, templates, setCurrentTemplate,currentTemplate, setAddPopupStatus}) => {


    const setTemplate = (tmpName) => {
        if (templates.length) {
            const currentTemplate = templates.find(t => t.name === tmpName);
            if (currentTemplate) {
                setCurrentTemplate(currentTemplate)
            }
        }
    }

    const handleSubmit = () => {
        setAddPopupStatus(true)
    }

    return <div className='modal_wrapper' onClick={() => closeModal()}>
        <div className="modal__bg"></div>

        <div className='modal' onClick={event => event.stopPropagation()}>
            <h3 className='modal__title-main'>New Postback</h3>

            <div className="divider"></div>

            <div className="modal__content-block">
                <p className="modal__title-inner">You can choose one of the postback templates</p>

                <select className="modal__select" onChange={(e) => setTemplate(e.target.value)}>
                    <option value="select" hidden>Select postback template</option>
                    {
                        templates && templates.map(t =>
                            <option key={t.id} value={t.name}>{t.name}</option>
                        )
                    }
                </select>

                <div className="modal__btns">
                    <DiscardButton onClick={() => closeModal()}></DiscardButton>
                    <CreateNewItemBtn disabled={currentTemplate === null} title={"Add New Postback"} onClick={() => handleSubmit()}></CreateNewItemBtn>
                </div>
            </div>
        </div>
    </div>
}

export default modal