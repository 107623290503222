export const modifyPostbackData = data => data.map(item => {
    const {created_at, updated_at, start_click_date, postback_status,  voluum_tracking_domain, ...other} = item;
    return modifyEmptyTableData({...other, postback_status,  start_click_date, voluum_tracking_domain});
});

export const modifyEmptyTableData = objData => {
    const newValuesObj = {};
    Object.entries(objData).forEach(itm => {
        if (itm[1] === null) {
            newValuesObj[itm[0]] = '';
        } else {
            newValuesObj[itm[0]] = itm[1];
        }
    })
    return newValuesObj;
}
