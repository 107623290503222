export const rowsLimitOptions = [
    {value: "10", label: "10 / page"},
    {value: "50", label: "50 / page"},
    {value: "100", label: "100 / page"}
];

export const orderDirectionOptions = [
    {value: "asc", label: "Ascending"},
    {value: "desc", label: "Descending"},
];

export const filterConditionOptions = [
    {value: "like", label: "Contains"},
    {value: "not like", label: "Not Contains"},
    {value: ">", label: "More than"},
    {value: "<", label: "Less than"},
    {value: "=", label: "Equal"},
    {value: "!=", label: "Not Equal"},
]

export const filterConditionOptionsForJson = [
    {value: "in_json", label: "Equal"},
    {value: "not_json", label: "Not Equal"},
    {value: "like", label: "Contains"},
    {value: "not like", label: "Not Contains"},
    {value: ">", label: "More than"},
    {value: "<", label: "Less than"},
]









