import {logOut} from "../redux/dataUploader/dataSlice";
import {addNewUserService} from "../services/user";
import {addNewUserOptimService} from "../services/new-user-auth";
import {getAuthorizationHeader} from "../helpers/get-token";
import {showNotification} from "../services/notifications";

const addNewUser = async (setFormLoading, setFormErrorMsg, setSuccessStatus, setFormSuccessMsg, setFormError, dispatch, data, resetForm) => {
    setFormLoading(true);

    const {name, email, password, role} = data
    const token = getAuthorizationHeader()

    try {
        const results = await addNewUserOptimService(name, email, password, role, token);
        if(results.data.message) {
            setFormError(false);
            setSuccessStatus(true);
            showNotification("success", results.data.message )
            resetForm({values: ''});
        } else {
            setFormError(true);
            setSuccessStatus(false);
        }
        setFormLoading(false);
    } catch (error) {
        setSuccessStatus(false);
        setFormLoading(false);
        if(error.response.data.message) {
            setFormError(true);
            showNotification(error.response.data.message);
            setFormErrorMsg(error.response.data.message);
        }
        showNotification("error", error.response.data.email[0])
        console.error('Error!',  error.response.data.email[0]);
        if (error.response.status === 401) {
            dispatch(logOut());
        }
    }
}

export default addNewUser;