import React, {useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import {FILE_TYPES} from "../../constants";
import {Alert} from "@mui/material";

const CustomFileUploaderNoParse = ({type, setFileLinksList, currentValues, setFormError, file, setFile}) => {
    const [fileError, setFileError] = useState(false);
    const [parsingErrors, setParsingErrors] = useState([]);

    const handleFileChange = files => {
        console.log(files);
        setFile(files[0]);
        
        setFileError(false);
        if(files[0].size > 1000000) {
            setFormError(true);
        } else {
            setFormError(false);
        }

        setFileLinksList(files[0]);
    };

    return (
        <>
            <FileUploader
                multiple={true}
                handleChange={handleFileChange}
                name="file"
                types={FILE_TYPES}
                disabled={currentValues.linksList !== ''}
                classes={`drop-label ${fileError ? 'error' : ''}`}
            />
            <p className='file-name'>{file && file.name ? `File name: ${file.name}` : currentValues.linksList !== ''? 'Data from the form will be used' : 'no file uploaded yet'}</p>
            {parsingErrors.length !== 0 && (
                <Alert severity="warning">
                    {parsingErrors.map(error => {
                        return (
                            <div>{error.message} (row {error.row})</div>
                        );
                    })}
                </Alert>
            )}
        </>
    );
};

export default CustomFileUploaderNoParse;