import React, {useState, useEffect} from "react";
import getReleaseNotifications from '../../../helpers/get-release-notifications';
import "./notifications.scss"

const NotificationPopups = () => {

  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    const allNotifications = await getReleaseNotifications();

    if(allNotifications) {
      setNotifications(allNotifications.data);
    }
  }

  useEffect(() => {
    getNotifications();
  }, []);

  const removeFromNotivicationsList = async (itemID) => {
    const token = localStorage.getItem("auth_token");

    try {
      const response = await fetch(`${localStorage.getItem('apiUrl')}api/system-notifications/${itemID}/close`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        method: 'POST'
      });
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const removeNotivicationFromView = (itemID) => {

    const newArray = notifications.filter(item => 
      String(item.id) !== itemID
    );

    setNotifications(newArray);
  }

  const closeNotificationPopup = (e) => {
    const currentItemID = e.target.parentNode.id;
    const idNumber = currentItemID.replace('notification-', '');

    removeNotivicationFromView(idNumber);
    removeFromNotivicationsList(idNumber);
  }

  return (
    <div className="notification-popup-cover">
      <div className="notifications">
        { notifications.map((item) => {
            return <div key={ item.id } id={`notification-${ item.id }`} className={ (item.show ? 'show' : 'hide') + ' notification-popup' } >
            <div className="notification-popup-text">
              <img src={`${process.env.PUBLIC_URL}/images/sidebar/${item.image}`} alt="logo" />
              <h6>{ item.title }</h6>
              <p dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
            <button className="notification-popup-close-button" onClick={ closeNotificationPopup }>+</button>
          </div>
        }) }
      </div>
    </div>
  );

}

export default NotificationPopups;