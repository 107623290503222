import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {accountInformationStart, accountSelected, setVoluumId} from "../../redux/dataUploader/dataSlice";
import GetClosedCampaignsData from "../../data-collectors/get-closed-campaigns-data";
import ClearFilters from "../../components/buttons/ClearFilters";
import ExcelDownload from "../../components/buttons/ExcelDownload";
import Loader from "../../components/loaders/Loader";
import CustomTabs from "../../components/CustomTabs";
import CustomSelect from "../../components/CustomSelect";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import {USER_PROJECT, USER_ROLE} from "../../constants/Auth";
import {
    CAMPAIGNS_TYPE,
    CLOSED_CAMPAIGNS_LIST_TABS,
    FLOWS_TYPE,
    OFFER_TYPE,
    PATH_TYPE
} from "../../constants";

const ClosedCampaignsList = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const dateRange = useSelector(state => state.dataUploader.dateRange);
    const projectName = localStorage.getItem(USER_PROJECT);
    const role = localStorage.getItem(USER_ROLE);
    const accountInformation = useSelector(state => state.dataUploader.accountInformation);
    const voluumId = useSelector(state => state.dataUploader.voluumId);
    const [closedCampaignsData, setClosedCampaignsData] = useState({
        offer: [],
        path: [],
        flow: [],
        campaign: []
    });
    const [changedClosedCampaignsData, setChangedClosedCampaignsData] = useState({
        offer: [],
        path: [],
        flow: [],
        campaign: []
    });
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [tabTitle, setTabTitle] = useState('offer');
    const [tableRef, setTableRef] = useState(null);

    useEffect(() => {
        dispatch(accountInformationStart(projectName));
    }, [dispatch, projectName]);

    useEffect(() => {
        if(accountInformation.length > 0) {
            const firstAcc = accountInformation.reduce((prev, curr) => +prev['voluum_id'] < +curr['voluum_id'] ? prev : curr);
            dispatch(setVoluumId(firstAcc.voluum_id));
            dispatch(accountSelected(firstAcc.project));
        }
    }, [dispatch, accountInformation, pathname]);

    useEffect(() => {
        if (((role === 'admin' && voluumId !== '') || voluumId !== '') && dateRange.filter(el => el === null).length !== 1) {
            GetClosedCampaignsData(setLoading, setError, dispatch, setClosedCampaignsData, setChangedClosedCampaignsData, tabTitle, voluumId, dateRange);
        }
    }, [dispatch, tabTitle, voluumId, dateRange, pathname]);

    const onDataTableChanged = params => {
        let newData = [];
        let {rowsToDisplay} = params.api.getModel();
        rowsToDisplay.forEach(el => {
            let obj = { ...el.data };
            newData.push(obj)
        });
        setChangedClosedCampaignsData(prevState => ({
            ...prevState,
            [tabTitle]: newData
        }));
    };

    const getTabTitle = title => {
        setTabTitle(title)
    };

    const clearFilters = () => {
        tableRef.api.setFilterModel(null);
    }

    const getTableRef = ref => {
        setTableRef(ref);
    }

    if (Object.values(isLoading).filter(el => el === false).length !== Object.keys(isLoading).length) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <div className="closed-campaigns-container">
            <div className={`page-btns-container${!menuStatus ? ' collapsed-menu' : ''}`}>
                {closedCampaignsData.offer.length > 0 ?
                    <>
                        <ExcelDownload name='closed_campaigns_data' titles={CLOSED_CAMPAIGNS_LIST_TABS} data={[changedClosedCampaignsData[OFFER_TYPE], changedClosedCampaignsData[PATH_TYPE], changedClosedCampaignsData[FLOWS_TYPE], changedClosedCampaignsData[CAMPAIGNS_TYPE]]} />
                        <ClearFilters clearFilters={clearFilters}/>
                    </> :
                    null}
                <CustomDateRangePicker isClearable={true} />
                {role === 'admin' ?
                    <CustomSelect data={accountInformation.filter(el => el['voluum_id'] < 1000)} setDispatchVoluumId={true} /> :
                    null
                }
            </div>

            {closedCampaignsData.offer.length > 0 ?
                <CustomTabs
                    data={[closedCampaignsData[OFFER_TYPE], closedCampaignsData[PATH_TYPE], closedCampaignsData[FLOWS_TYPE], closedCampaignsData[CAMPAIGNS_TYPE]]}
                    titles={CLOSED_CAMPAIGNS_LIST_TABS}
                    getTabTitle={getTabTitle}
                    closedCampaignsStatus={true}
                    onDataTableChanged={onDataTableChanged}
                    getTableRef={getTableRef}
                /> :
                <>
                    <h3>No data for this account.</h3>
                </>
            }
        </div>
    )
}

export default ClosedCampaignsList;