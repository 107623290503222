const getReleaseNotifications = async () => {
  const token = localStorage.getItem("auth_token");

  return await fetch(`${localStorage.getItem('apiUrl')}api/system-notifications/${localStorage.getItem('id')}`, {
      headers: {
          method: 'GET',
          'Authorization': `Bearer ${token}`,
          "Accept": "application/json",
          "Content-Type": "application/json"
      },
    })
    .then(res => {
        if (res.ok) {
          return res.json();
        }
        return Promise.reject(res)
    })
    .then(res => {
        return res;
    })
    .catch(e => console.error('Get notification Error: ', e));

}

export default getReleaseNotifications;