import {getAuthorizationHeader} from "../helpers/get-token";
import {updateUserPassword} from "./new-user-auth";

const userResetPassword = async (oldPassword, newPassword) => {
    const token = getAuthorizationHeader();

    const res = await updateUserPassword(oldPassword, newPassword, token);
    return res;
}

export default userResetPassword;