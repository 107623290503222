export const getAnOfferCountries = async () => {
    return await fetch("https://dev-api.optim8.io/api/countries", {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            return Promise.reject(res)
        })
        .then(res => {
            return res
        })
        .catch(e => console.error(e))
}

