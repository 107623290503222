import {all, takeEvery, put, fork, call} from 'redux-saga/effects';
import {getAuthorizationHeader} from '../../helpers/get-token';
import {
    USER_PROJECT,
    USER_ID,
    USER_NAME,
    USER_ROLE,
    USER_EMAIL
} from '../../constants/Auth';
import {
    logIn,
    logOut,
    logOutAuto,
    setLoadingState,
    setSuccessfullyState,
    setErrorSubmitted,
    accountDetails,
    accountSelected,
    voluumAlerts,
    affiliateNetworks,
    accountInformation, voluumId
} from "../dataUploader/dataSlice";
import {
    logInService,
    isAuthService,
    logOutService,
    getProjectConnectionsService,
    getAccountsInformationService
} from "../../services/user";

import {
    logInOptimService,
    logOutOptimeService,
    isAuthOptimService,
    addNewUserOptimService,
} from "../../services/new-user-auth"

import {accountDetailsService, getAffiliateNetworksService} from "../../services/yieldkit";
import parseVoluumAccountDetails from "../../helpers/parse-voluum-account-details";
import {getVoluumAlertsService} from "../../services/voluum";
import checkUser from "../../helpers/check-user";

export function* logInSaga() {
    yield takeEvery('dataUploader/logInStart', function* ({payload}) {
        const {email, password} = payload;

        try {
            yield put(setLoadingState(true));
            yield put(accountSelected(''));

            const userResponse = yield call(logInOptimService, email, password);

            yield put(setLoadingState(false));
            yield put(setSuccessfullyState(true));

            const {data} = userResponse;

            if (data.code === 403) {
                yield put(setErrorSubmitted(true));
                return;
            }

            const token = data.access_token;

            if (token) {
                const userInfo = yield call(isAuthOptimService, token);
                const allUsers = yield call(getProjectConnectionsService);

                if (userInfo.data) {
                    const userData = userInfo.data.data;
                    const projectName = checkUser(allUsers.data.data, userData.email);

                    localStorage.setItem(USER_PROJECT, projectName);
                    localStorage.setItem(USER_ID, userData.id);
                    localStorage.setItem(USER_NAME, userData.name);
                    localStorage.setItem(USER_ROLE, userData.role);
                    localStorage.setItem(USER_EMAIL, userData.email);
                }

                yield put(logIn({'token': token, 'otp_enabled': false}))
            }

        } catch (err) {
            yield put(setLoadingState(false));
            yield put(setErrorSubmitted(true));
            console.error('Login error', err);
        }
    });
}

export function* accountDetailsSaga() {
    yield takeEvery('dataUploader/accountDetailsStart', function* (action) {
        try {
            const accResult = yield call(accountDetailsService, action.payload); // eslint-disable-line no-unused-vars
            const account = parseVoluumAccountDetails(accResult.data.data);
            yield put(accountDetails(account));
            account.length === 1 ? yield put(accountSelected(account[0].name)) : yield put(accountSelected(''));
        } catch (err) {
            console.error('Account Details error:', err)
        }
    });
}

export function* accountsInformationSaga() {
    yield takeEvery('dataUploader/accountInformationStart', function* (action) {
        try {
            const result = yield call(getAccountsInformationService, action.payload); // eslint-disable-line no-unused-vars
            yield put(accountInformation(result.data.data));
            if(result.data.data.length === 1) {
                yield put(voluumId(result.data.data[0].voluum_id));
            } else {
                yield put(voluumId(''));
            }
        } catch (err) {
            console.error('Account Information error:', err)
        }
    });
}

export function* affiliateNetworksSaga() {
    yield takeEvery('dataUploader/affiliateNetworksStart', function* () {
        try {
            const networksResult = yield call(getAffiliateNetworksService); // eslint-disable-line no-unused-vars
            yield put(affiliateNetworks(networksResult.data.data));
        } catch (err) {
            console.error('Account Details error:', err)
        }
    });
}

export function* voluumAlertsSaga() {
    yield takeEvery('dataUploader/voluumAlertsStart', function* (action) {
        try {
            const alerts = yield call(getVoluumAlertsService, action.payload); // eslint-disable-line no-unused-vars
            const allVoluumAlerts = alerts.data.data;
            yield put(voluumAlerts(allVoluumAlerts));
        } catch (err) {
            console.error('Account Details error:', err)
        }
    });
}

export function* getUserInfoSaga() {
    yield takeEvery('dataUploader/getUserInfo', function* () {
        try {
            const token = getAuthorizationHeader()
            const userInfo = yield call(isAuthOptimService, token); // eslint-disable-line no-unused-vars
            if(userInfo.data.data.status === 401) {
                alert('The token has expired. Log in again, please.');
                yield put(logOutAuto());
            }
        } catch (err) {
            if (err.response.status === 401) {
                alert('The token has expired. Log in again, please.');
                yield put(logOutAuto());
            }
        }
    });
}

export function* logOutAutoSaga() {
    yield takeEvery('dataUploader/logOutAuto', function* () {
        //get token
        const token = getAuthorizationHeader();

        try {
            yield call(logOutOptimeService, token); // eslint-disable-line no-unused-vars
            yield put(logOut());
            localStorage.clear();
        } catch (err) {
            yield put(logOut());
            localStorage.clear();
        }
    });
}

export function* logOutSaga() {
    yield takeEvery('dataUploader/logOutStart', function* () {
        //get token
        const token = getAuthorizationHeader();

        try {
            yield call(logOutOptimeService, token); // eslint-disable-line no-unused-vars
            yield put(logOut());
            localStorage.clear();
        } catch (err) {
            yield put(logOut());
            localStorage.clear();
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(logInSaga),
        fork(accountDetailsSaga),
        fork(accountsInformationSaga),
        fork(affiliateNetworksSaga),
        fork(voluumAlertsSaga),
        fork(getUserInfoSaga),
        fork(logOutSaga),
        fork(logOutAutoSaga),
    ]);
}
