import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {HOMEPAGE_CONTENT} from "../../constants";
import {USER_ID, USER_NAME} from "../../constants/Auth";
import getUserData from "../../services/get-user-info";
import {logOut} from "../../redux/dataUploader/dataSlice";
import './home.scss';
import {isAuthOptimService} from "../../services/new-user-auth";
import {getAuthorizationHeader} from "../../helpers/get-token";

const Home = () => {
    const userName = localStorage.getItem(USER_NAME);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const dispatch = useDispatch();
    const userId = Number(localStorage.getItem(USER_ID));

    const getDataUser = async (userId) => {
        try {
            // await getUserData(userId);
            const token = getAuthorizationHeader()
            await isAuthOptimService(token)
        } catch (err) {

            console.log(err);
            if (err.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    useEffect(() => {
        getDataUser(userId);
    }, []);

    return (
        <>
            <div className={`home-bg-container gradient-container${!menuStatus ? ' collapse-menu' : ''}`}>
                <h3>Hello {userName ? userName.charAt(0).toUpperCase() + userName.slice(1) : null}!</h3>
                <p>Please find below a short description of the primary sections available within this administration panel:</p>
            </div>
            <div className="homepage-content-container">
                <ul>
                    {HOMEPAGE_CONTENT.map((el, i) => {
                        return (
                            <li key={i}>
                                <div className="img-block">
                                    <img src={`${process.env.PUBLIC_URL}/images/homepage/${el.name.toLowerCase().replaceAll(' ', '_')}.svg`} alt={el.name}/>
                                </div>
                                <h4>{el.name}</h4>
                                <p>{el.description}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default Home