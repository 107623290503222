import authApiInstance from "./new-axios-instance";


/*
* Login user
*/
export const logInOptimService = async (email, password) => await authApiInstance.post('/login', {"email": email, "password": password})


/*
* Register new user
*/
export const addNewUserOptimService = async (name, email, password, role, token) => await authApiInstance.post('/register', {
    "name": name,
    "email": email,
    "password": password,
    "role": role,
}, {
    headers: {
        "Authorization": `Bearer ${token}`
    }
})


/*
* Change password
*/
export const updateUserPassword = async (oldPassword, newPassword, token) => await authApiInstance.post('/update-password', {
    "old_password": oldPassword,
    "password": newPassword
}, {
    headers: {
        "Authorization": `Bearer ${token}`
    }
})


/*
* Get user info
*/
export const isAuthOptimService = async (token) => await authApiInstance.get('/me', {
    headers: {
        "Authorization": `Bearer ${token}`
    }
});


/*
* Forgot password
*/
export const forgotPasswordOptimeService = async (email) => await authApiInstance.post('/forgot', {"email": email});


/*
* Logout
*/
export const logOutOptimeService = async (token) => await authApiInstance.post('/logout', {"token": token}, {
    headers: {
        "Authorization": `Bearer ${token}`
    }
});