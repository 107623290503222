import {createSlice} from '@reduxjs/toolkit';
import initialState from "../initialState";

export const dataSlice = createSlice({
    name: 'dataUploader',
    initialState: initialState,
    reducers: {
        setCurrentPath: (state, action) => {
            state.currentPath = action.payload;
        },
        setLoadingState: (state, action) => {
            state.isLoading = action.payload;
        },
        setSuccessfullyState: (state, action) => {
            state.isSuccessfully = action.payload;
        },
        setErrorSubmitted: (state, action) => {
            state.errorSubmitted = action.payload;
        },
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setDateRangeEmpty: (state, action) => {
            state.dateRangeEmpty = action.payload;
        },
        collapseMenu: (state, action) => {
            state.collapseMenu = action.payload;
        },
        logInStart: (state, action) => {
        },
        getUserInfo: () => {
        },
        logIn: (state, action) => {
            const {token} = action.payload;
            state.token = token;
            state.userOtpEnabled = null;
        },
        logOutStart: (state) => {

        },
        logOut: (state) => {
            state.token = null;
            state.userOtpEnabled = null;
            localStorage.clear();
        },
        logOutAuto: (state) => {
            state.token = null;
            state.userOtpEnabled = null;
        },
        getSitesData: () => {
        },
        sites: (state, action) => {
            state.users = action.payload;
        },
        accountDetailsStart: () => {
        },
        accountDetails: (state, action) => {
            state.accountDetails = action.payload;
        },
        accountInformationStart: () => {
        },
        accountInformation: (state, action) => {
            state.accountInformation = action.payload;
        },
        voluumId: (state, action) => {
            state.voluumId = action.payload;
        },
        setVoluumId: (state, action) => {
            state.voluumId = action.payload;
        },
        voluumAlertsStart: () => {
        },
        voluumAlerts: (state, action) => {
            state.voluumAlerts = action.payload;
        },
        accountSelected: (state, action) => {
            state.accountSelected = action.payload;
        },
        itemToUpdate: (state, action) => {
            state.voluumAlertItem = action.payload;
        },
        itemToUpdateStatus: (state, action) => {
            state.voluumAlertItemStatus = action.payload;
        },
        setHiddenColumns: (state, action) => {
            state.hiddenColumns = action.payload;
        },
        affiliateNetworksStart: () => {
        },
        affiliateNetworks: (state, action) => {
            state.affiliateNetworks = action.payload;
        },
        settingsViewSelected: (state, action) => {
            state.settingsViewSelected = action.payload;
        },
        setIsVoluumCSVdifferentColumn: (state, action) => {
            state.isVoluumCSVdifferentColumn = action.payload;
        },
    },
})

export const {
    setCurrentPath,
    setLoadingState,
    setSuccessfullyState,
    setErrorSubmitted,
    setDate,
    setDateRange,
    setDateRangeEmpty,
    collapseMenu,
    logIn,
    logInStart,
    getUserInfo,
    logOutStart,
    logOut,
    logOutAuto,
    getSitesData,
    accountDetailsStart,
    accountDetails,
    accountInformationStart,
    accountInformation,
    voluumId,
    setVoluumId,
    voluumAlertsStart,
    voluumAlerts,
    accountSelected,
    itemToUpdate,
    itemToUpdateStatus,
    setHiddenColumns,
    affiliateNetworksStart,
    affiliateNetworks,
    settingsViewSelected,
    setIsVoluumCSVdifferentColumn,
} = dataSlice.actions;

export default dataSlice.reducer;