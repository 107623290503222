import {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import Router from "../routes/Router";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import {DEV_ENV_VARIABLES} from "../constants";

const App = () => {
    useEffect(() => {
        if(!localStorage.getItem('dev-env') || !localStorage.getItem('apiUrl')) {
            localStorage.setItem('dev-env', DEV_ENV_VARIABLES.dev.dev_name);
            localStorage.setItem('apiUrl', DEV_ENV_VARIABLES.dev.link);
        }
    }, [])

    return (
        <BrowserRouter>
            <div className="main-container">
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Router />
                </ErrorBoundary>
            </div>
        </BrowserRouter>
    )
}

export default App;
