import {useEffect, useState} from "react";
import Select from "react-select";
import ClearFilters from "../buttons/ClearFilters";
import "./affiliate-network-offers-table-filter.scss";
import {InputMask} from "@react-input/mask";
import Loader from "../loaders/TableLoader";
import {filterConditionOptions, filterConditionOptionsForJson} from "../../pages/AffiliateNetworkOffers/table-options"

const AffiliateNetworkOffersTableFilter = ({
                                               orderByOptions = [],
                                               orderDirectionOptions = [],
                                               searchByColumnOptions = [],
                                               selectedFilters,
                                               handleGettingOfferWithSearchParams,
                                               changeSelectedFilters,
                                               clearSearchFilters,
                                               setFilterParams,
                                               filterParams,
                                               setTags,
                                               tags,
                                               selectedANAccount,
                                               selectedANType,
                                               isLoading,
                                               setIsLoading
                                           }) => {
    const [searchValue, setSearchValue] = useState("");
    const [selectedColumnToSearch, setSelectedColumnToSearch] = useState(null);
    const [selectedSearchCondition, setSelectedSearchCondition] = useState({value: 'like', label: 'Contains'});
    const [conditionOptions, setConditionOptions] = useState([]);
    const [errors, setErrors] = useState("");
    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "12px",
            lineHeight: "1.2",
            minHeight: "26px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px",
        }),
        clearIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px",
        }),
        multiValueLabel: (baseStyles, state) => ({
            ...baseStyles,
            padding: "1px",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "12px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    }

    useEffect(() => {
        setConditionOptions(filterConditionOptions);
    }, []);

    const getOffersWithSearchParams = () => {

        let filterParam = {};
        let temp = {};
        let tempTag = [...tags];
        let correctOperator = selectedSearchCondition.value;
        let formattedValue = searchValue;

        if (correctOperator === "=" && (selectedColumnToSearch.value === "offer_domain" || selectedColumnToSearch.value === "offer_country")) {
            correctOperator = "in_json";
        }
        
        if (correctOperator === "!=" && (selectedColumnToSearch.value === "offer_domain" || selectedColumnToSearch.value === "offer_country")) {
            correctOperator = "not_json";
        }

        if (correctOperator === "not_json" && (selectedColumnToSearch.value === "available_affiliate_networks")) {
            correctOperator = "not like";
        }

        if (correctOperator === "not like" && (selectedColumnToSearch.value === "offer_domain" || selectedColumnToSearch.value === "offer_country")) {
            correctOperator = "not_json";
        }

        if (correctOperator === "in_json" && (selectedColumnToSearch.value === "available_affiliate_networks")) {
            correctOperator = "like";
        }

        if (correctOperator === "not_json" && (selectedColumnToSearch.value === "available_affiliate_networks")) {
            correctOperator = "not like";
        }

        if (correctOperator === "like" || correctOperator === "not like") {
            formattedValue = `%${searchValue}%`;
        }

        if (selectedColumnToSearch.value === "offer_country") {
            formattedValue = formattedValue.replaceAll('%', '');
        }

        if (selectedColumnToSearch.value && searchValue !== "") {
            filterParam[selectedColumnToSearch.value] = {
                operator: correctOperator,
                value: formattedValue
            }

            for (let tag of tempTag) {
                if (tag.name === selectedColumnToSearch.value) {
                    tempTag = tags.filter(t => t.name !== tag.name)
                }
            }

            tempTag.push({
                name: selectedColumnToSearch.value,
                operator: selectedSearchCondition.label,
                value: searchValue,
            });
        }

        setTags(tempTag);

        temp = Object.assign(filterParams, filterParam);
        setFilterParams({...temp});
        handleGettingOfferWithSearchParams(temp);

        setSearchValue("");
    }

    const filterConditionValues = (option) => {
        if (option.value === "offer_domain" || option.value === "offer_country" || option.value === "available_affiliate_networks") {
            setConditionOptions(filterConditionOptionsForJson)
        } else {
            setConditionOptions(filterConditionOptions)
        }
    }

    const handleRemoveColumnFromSearch = (name) => {
        let temp = [...tags];
        let tempFilters = {...filterParams};
        let filteredTags = temp.filter(t => t.name !== name);

        delete tempFilters[name];
        setTags(filteredTags);
        setFilterParams(tempFilters);
        handleGettingOfferWithSearchParams(tempFilters);
        setSearchValue("");

    }


    useEffect(() => {
        if (selectedColumnToSearch?.value === "available_affiliate_networks" && searchValue.length < 3) {
            setErrors("Please enter 3 or more characters.");
        } else {
            setErrors("");
        }

        if (searchValue === "") {
            setErrors("");
        }
    }, [selectedColumnToSearch, searchValue]);

    return (

        <div className="data-table-filters-wrap">
            <div className="data-table-order-wrap">
                {orderByOptions.length > 0 && (
                    <Select
                        className={"sort-select order-by-select"}
                        styles={selectStyles}
                        options={orderByOptions}
                        defaultValue={selectedFilters.orderBy}
                        isSearchable={false}
                        placeholder="Select option:"
                        isClearable={true}
                        name={"order_by"}
                        onChange={(option) => {
                            changeSelectedFilters({
                                ...selectedFilters,
                                orderBy: option,
                            })
                        }}
                    />
                )}

                {orderDirectionOptions.length > 0 && (
                    <Select
                        className={"sort-select order-direction-select"}
                        styles={selectStyles}
                        options={orderDirectionOptions}
                        defaultValue={selectedFilters.orderDirection}
                        isSearchable={false}
                        name={"order_direction"}
                        onChange={(option) => {
                            changeSelectedFilters({
                                ...selectedFilters,
                                orderDirection: option,
                            })
                        }}
                    />
                )}
            </div>


            <div className="data-table-filter-wrap">
                <Select
                    className={"sort-select search-in-column-select"}
                    styles={selectStyles}
                    options={searchByColumnOptions}
                    isSearchable={false}
                    isClearable={true}
                    name={"search_in_column"}
                    onChange={(option) => {
                        setSelectedColumnToSearch(option)
                        filterConditionValues(option)
                        setSearchValue("")
                    }}
                />

                <Select
                    className={"sort-select"}
                    styles={selectStyles}
                    options={conditionOptions}
                    defaultValue={selectedSearchCondition}
                    isSearchable={false}
                    name={"filter_conditions"}
                    onChange={(options) => {
                        setSelectedSearchCondition(options)
                    }}
                />

                {selectedColumnToSearch?.type === "date" && (
                    <InputMask
                        mask="yyyy-mm-dd hh:mm:ss"
                        placeholder="yyyy-mm-dd hh:mm:ss"
                        className="sort-input"
                        replacement={{d: /\d/, m: /\d/, y: /\d/, h: /\d/, s: /\d/,}}
                        separate
                        onChange={e => {
                            setSearchValue(e.target.value)
                        }}
                    />

                )}

                {selectedColumnToSearch?.type !== "date" && (
                    <div className={"search-bar-wrapper"}>
                    <input
                        type="text"
                        className="sort-input"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        placeholder="Search..."
                    />
                        {errors && <p className="error-message">{errors}</p>}
                    </div>
                )}

                <div className="data-table-search-btns-wrap">
                    <ClearFilters
                        text={"Clear"}
                        clearFilters={clearSearchFilters}
                    />
                    <button
                        className={"blue-btn"}
                        onClick={getOffersWithSearchParams}
                        disabled={!Boolean(selectedColumnToSearch) || !Boolean(searchValue) || errors}
                    >
                        Apply
                    </button>
                </div>
            </div>

            { selectedANAccount && selectedANType &&
                <ul className={"filter-tags-container"} style={{width: "100%"}}>
                    {tags.length > 0 && tags.map((tag) => {

                        return <li key={tag.name} className={"filter-tags"}>
                            {tag.name.toLowerCase()}: <strong> {tag.operator.toLowerCase()} </strong> {tag.value.toLowerCase()}
                            <button
                                id={tag.name}
                                onClick={() => handleRemoveColumnFromSearch(tag.name)}
                            ><img src="/images/close-icon.svg" alt="close"/></button>
                        </li>
                    })}
                </ul>
            }
            

        </div>
    )
}

export default AffiliateNetworkOffersTableFilter;