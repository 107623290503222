import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import Loader from "../../loaders/Loader";
import { USER_PROJECT, USER_ROLE } from "../../../constants/Auth";

import { createPostback, updatePostback } from "../../../services/postbacks";
import "react-tagsinput/react-tagsinput.css";
import './add-item.scss'
import '../../forms/UpdateConfigForm/update-config-form.scss'

const PostbackPopup = ({
                           initialValues,
                           setInitialValues,
                           closePopup,
                           accountDetails,
                           setIsTriggerUpdate,
                           isPopupType,
                           closeModal,
                           anAccounts,
                           setCurrentTemplate,
                           currentTemplate,
                           token
                       }) => {
    const role = localStorage.getItem(USER_ROLE);
    const currentItemData = useSelector((state) => state.dataUploader.voluumAlertItem);
    const [fieldsError, setFieldsError] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (isPopupType === 'create') {
            setInitialValues(initialValues);
            return setIsLoading(false);
        }

        const currentItemDataLength = Object.keys(currentItemData).length;
        if (currentItemDataLength) {
            if (!currentItemData.start_click_date) {
                setInitialValues(previousState => ({
                    ...previousState,
                    start_click_date: currentItemData.postback_template_id === 1 ? "2024-01-01" : "2020-01-01"
                }));
            } else {
                setInitialValues(currentItemData);
            }
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, [currentItemData, setInitialValues]);

    const validatePostbackName = value => {
        if (value === '') {
            return 'Name is required';
        }
    };

    const validateOther = value => {
        const regex = /^[a-zA-Z0-9 !@#$%^&*()_+\-='"\\|,.?]*$/;
        if (value === '') {
            return 'Required field';
        } else if (!regex.test(value)) {
            return 'No special symbols';
        }
    };

    const validateSpecialSymbols = value => {
        const regex = /^[a-zA-Z0-9 !@#$%^&*()_+\-='"\\|,.?]*$/;
        if (!regex.test(value)) {
            return 'No special symbols';
        }
    };

    const validateNumber = value => {
        if (value === '') {
            return 'Required field';
        } else if (!/^(|-?\d+)$/.test(value)) {
            return 'Numbers only';
        }
    };

    const validateDomain = value => {
        if (value === '') {
            return 'Required field';
        } else if (!/([\wЁёА-я\d-]{2,})\.([\wЁёА-я\d-]{2,})/.test(value)) {
            return 'Domain name is incorrect';
        } else if (value.includes("https")) {
            return 'Pass domain without https://';
        }
    };

    const validateDate = (value) => {
        if (value && value.length > 0) {
            const [year, month, day] = value.split('-').map(Number);
            const date = new Date(year, month - 1, day);
            if (
                date.getFullYear() !== year ||
                date.getMonth() + 1 !== month ||
                date.getDate() !== day
            ) {
                return 'Invalid date';
            }
        }
    };

    const onSubmit = async (values) => {
        setFormLoading(true);
        try {
            if (isPopupType === 'create') {
                if (values.start_click_date < initialValues.start_click_date || values.start_click_date === "") {
                    values.start_click_date = initialValues.start_click_date;
                }

                await createPostback(values, token);
                setFormLoading(false);
                setCurrentTemplate(null);
                setIsTriggerUpdate(true);
                closePopup();
                closeModal();
            } else {
                const defaultStartDate = (values.postback_template_id === 1) ? "2024-01-01" : "2020-01-01";

                if (!values.start_click_date || values.start_click_date < defaultStartDate) {
                    values.start_click_date = defaultStartDate;
                }

                await updatePostback(values, token);
                setFormLoading(false);
                setIsTriggerUpdate(true);
                closePopup();
                closeModal();
            }
            setFormLoading(false);
        } catch (error) {
            setFieldsError(true);
            setFormLoading(false);
        }
    };

    const inputOrder = [
        "postback_name",
        "et",
        "postback_status",
        "account_name",
        "voluum_tracking_domain",
        "start_click_date"
    ];

    return (
        <div className='add-item-popup-container'>
            <div className="add-item-popup-block">
                <div className='form-wrap'>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={onSubmit}>
                        {({ errors, touched }) => (
                            <Form>
                                <div className="title-wrap">
                                    <h1>
                                        {isPopupType === 'updating' ? 'Update' : 'Create new postback'}
                                    </h1>
                                    <div className="close-btn" onClick={closePopup}>
                                        <img src={`${process.env.PUBLIC_URL}/images/close-btn.svg`} alt="close popup" />
                                    </div>
                                </div>
                                {
                                    isLoading ?
                                        <Loader />
                                        :
                                        <>
                                            <div className="form-flex-container">
                                                {inputOrder.map(inputItem => {
                                                    if (inputItem === 'postback_name') {
                                                        return (
                                                            <div
                                                                className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                key={inputItem}>
                                                                <label htmlFor={inputItem}>{inputItem}</label>
                                                                <Field
                                                                    id={inputItem}
                                                                    name={inputItem}
                                                                    type='text'
                                                                    validate={validatePostbackName}
                                                                />
                                                                {errors[inputItem] || touched[inputItem] ? <div
                                                                    className='form-error'>{errors[inputItem]}</div> : null}
                                                            </div>
                                                        );
                                                    }
                                                    if (inputItem === 'et') {
                                                        return (
                                                            <div
                                                                className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                key={inputItem}>
                                                                <label htmlFor={inputItem}>{inputItem}</label>
                                                                <Field
                                                                    id={inputItem}
                                                                    name={inputItem}
                                                                    type='text'
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (inputItem === 'postback_status') {
                                                        return (
                                                            <div
                                                                className={`input-block without-config-item-block${errors[inputItem] || touched[inputItem] ? ' form-error' : ''}`}
                                                                key={inputItem}>
                                                                <label htmlFor={inputItem}
                                                                       className="toggle-label">{inputItem}</label>
                                                                <Field as="select" name={inputItem}>
                                                                    {["active", "deactivated"].map((option) => {
                                                                        return (
                                                                            <option key={option}
                                                                                    defaultValue="deactivated"
                                                                                    value={option}>{option}</option>
                                                                        );
                                                                    })}
                                                                </Field>
                                                            </div>
                                                        );
                                                    }
                                                    if (inputItem === 'account_name') {
                                                        return (
                                                            <div
                                                                className={`input-block without-config-item-block${errors[inputItem] || touched[inputItem] ? ' form-error' : ''}`}
                                                                key={inputItem}>
                                                                <label htmlFor={'service_credential_id'}
                                                                       className="toggle-label">account_name</label>
                                                                <Field as="select" name={"service_credential_id"}>
                                                                    {anAccounts.map((option) => {
                                                                        if (option.value === "all") {
                                                                            return
                                                                        }
                                                                        return (
                                                                            <option key={option.value}
                                                                                    value={option.value}>{option.label}
                                                                            </option>
                                                                        );

                                                                    })}
                                                                </Field>
                                                            </div>
                                                        );
                                                    }
                                                    if (inputItem === 'voluum_tracking_domain') {
                                                        return (
                                                            <div
                                                                className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                key={inputItem}>
                                                                <label htmlFor={inputItem}
                                                                       className='disabled-label'>{inputItem}</label>
                                                                <Field
                                                                    id={inputItem}
                                                                    name={inputItem}
                                                                    type='text'
                                                                    validate={validateDomain}
                                                                />
                                                                {errors[inputItem] || touched[inputItem] ? <div
                                                                    className='form-error'>{errors[inputItem]}</div> : null}
                                                                {isPopupType === 'create' && <span className="hint">example: VoluumTrackingDomain.com</span>}
                                                            </div>
                                                        );
                                                    }

                                                    if (inputItem === 'start_click_date') {
                                                        return (
                                                            <div
                                                                className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                                key={inputItem}>
                                                                <label htmlFor={inputItem}
                                                                       className='disabled-label'>{inputItem}</label>
                                                                <Field
                                                                    id={inputItem}
                                                                    name={inputItem}
                                                                    type='text'
                                                                    placeholder="YYYY-MM-DD"
                                                                    validate={validateDate}
                                                                />
                                                                {errors[inputItem] ? <div
                                                                    className='form-error'>{errors[inputItem]}</div> : null}
                                                            </div>
                                                        );
                                                    }

                                                    return (
                                                        <div
                                                            className={`input-block without-config-item-block${errors[inputItem] && touched[inputItem] ? ' form-error' : ''}`}
                                                            key={inputItem}>
                                                            <label htmlFor={inputItem}>{inputItem}</label>
                                                            <Field
                                                                id={inputItem}
                                                                name={inputItem}
                                                                type='text'
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="config-btns-block">
                                                <div className="discard-btn" onClick={closePopup}>Discard</div>
                                                <button className='update-btn'
                                                        type="submit">{isPopupType === 'updating' ? 'Update' : 'Create'}
                                                </button>
                                            </div>
                                        </>
                                }
                            </Form>
                        )}
                    </Formik>
                    {formLoading ? <div className="config-btns-loader-block"><Loader /></div> : null}
                    {formError ?
                        <div className="config-btns-message-block"><p className='new-user-error'>Some error occurred</p>
                        </div> : ''}
                    {formSuccess ? <div className="config-btns-message-block"><p className='new-user-success'>Account
                        is {isPopupType === 'updating' ? 'updated' : 'created'}</p></div> : ''}
                </div>
            </div>
        </div>
    )
}

export default PostbackPopup;
