import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation} from "react-router-dom";
import {MdMenuOpen} from "react-icons/md";
import {collapseMenu, logOutStart} from "../../redux/dataUploader/dataSlice";
import {useDispatch, useSelector} from "react-redux";
import {NOT_SIDEBAR_LINKS, SIDEBAR_LINKS, DEV_ENV_VARIABLES} from "../../constants";
import {USER_NAME, USER_PROJECT, USER_ROLE} from "../../constants/Auth";

import Select from 'react-select';

import "./header.scss";

const Header = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const popupRef = useRef(null);
    const userName = localStorage.getItem(USER_NAME);
    const projectName = localStorage.getItem(USER_PROJECT);
    const role = localStorage.getItem(USER_ROLE);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const [title, setTitle] = useState('');
    const [userMenuPopup, setUserMenuPopup] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(pathname.includes('not-found')) {
            setTitle('')
        } else {
            Object.values(SIDEBAR_LINKS).forEach(arr => {
                arr.filter(el => pathname === el.link || `${pathname}/` === el.link ? setTitle(el.title) : null);
            })
            NOT_SIDEBAR_LINKS.forEach(el => pathname === el.link || `${pathname}/` === el.link ? setTitle(el.title) : null);
        }
    }, [pathname])

    const collapseSidebar = () => {
        dispatch(collapseMenu(!menuStatus));
    }

    const toggleUserMenu = () => {
        setUserMenuPopup(!userMenuPopup);
    }

    const handleClickOutside = e => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setUserMenuPopup(false);
        }
    };

    const logOutBtn = async () => {
        dispatch(logOutStart());
        setUserMenuPopup(false);
    }

    return (
        <header className='main-header'>
            <div className={`logo-container${menuStatus ? '' : ' collapse-menu'}`}>
                <Link to='/'>
                    <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="logo"/>
                </Link>
            </div>
            <div className={`header-inner-container${menuStatus ? '' : ' collapse-menu'}`}>
                <div className="title-container">
                    <div className={`menu-icon${menuStatus ? '' : ' collapse-menu'}`} onClick={collapseSidebar}>
                        {menuStatus ? <MdMenuOpen/> : <MdMenuOpen/>}
                    </div>
                    <h1>{title}</h1>
                </div>
                <div className="user-container">
                    <div className="user-inner-container" ref={popupRef}>
                        <div className="user-image">{userName ? userName.charAt(0).toUpperCase() : ''}</div>
                        <div className="user-info">
                            <p className="user-name">{userName ? userName.charAt(0).toUpperCase() + userName.slice(1) : 'User'}</p>
                            <p className="user-project">{projectName ? projectName : role !== 'admin' ? 'User' : 'Admin'}</p>
                        </div>
                        <button className={`user-menu-button ${userMenuPopup ? ' open' : ''}`} onClick={toggleUserMenu}>
                            <img src={`${process.env.PUBLIC_URL}/images/arrow-header.svg`} alt="open user menu"/>
                        </button>
                        {userMenuPopup ?
                            <ul className='user-menu-popup'>
                                <li className='user-menu-popup-item'>
                                    <Link to='/user-profile/' onClick={toggleUserMenu}>User Profile</Link>
                                </li>
                                <li className='user-menu-popup-item'>
                                    <span onClick={logOutBtn}>Sign Out</span>
                                </li>
                            </ul> :
                            null
                        }
                    </div>
                    <div className="pcom-logo">
                        <img src={`${process.env.PUBLIC_URL}/images/pcom-logo.svg`} alt="pcom-logo"/>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
