import React from "react";
import "../buttons.scss";
import './create-new-item-btn.scss';

const CreateNewItemBtn = ({onClick, title, disabled}) => {
    return (
        <button disabled={disabled} className="item-create-button blue-btn" onClick={onClick}>{title ? title : "+Create new"}</button>
    )
}

export default CreateNewItemBtn;