import React from 'react';
import "./changed-campaigns-tabs.scss";

const ChangedCampaignsTabs = ({titles, currentTab, setCurrentTab}) => {
    return (
            <div className="tab-list-container">
                <ul className="tab-list">
                    {titles.map(title => (
                        <li onClick={() => setCurrentTab(title)} key={title.label} className={`${currentTab.label === title.label ? "tab active" : "tab"}`}>{title.label}</li>
                    ))}
                </ul>
            </div>
    );
};

export default ChangedCampaignsTabs;