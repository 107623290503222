import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {USER_PROJECT, USER_ROLE, USER_EMAIL} from "../../constants/Auth";
import Loader from "../../components/loaders/Loader";
import TableLoader from "../../components/loaders/TableLoader";
import PreTableYieldkitOffersListData from "../../components/tables/PreTableYieldkitOffersListData";
import GetYieldkitOffersListData from "../../data-collectors/get-yieldkit-offers-list-data";
import GetActiveYieldkitOffersListData from "../../data-collectors/get-active-yieldkit-offers-list-data";
import CsvDownload from "../../components/buttons/CsvDownload";
import rewriteTrackingLink from "../../helpers/rewrite-tracking-link";
import sortAccParams from "../../helpers/sort-acc-params";
import prepareVoluumFormatCSVData from "../../helpers/prepare-voluum-format-csv-data";
import {accountInformationStart, accountSelected as accountSelectedFunc, logOut} from "../../redux/dataUploader/dataSlice";
import CustomOpenSelect from "../../components/CustomOpenSelect";
import ClearFilters from "../../components/buttons/ClearFilters";
import OpenPopupBtn from "../../components/buttons/OpenPopupBtn";
import CSVinVoluumFormatPopup from "../../components/popups/CSVinVoluumFormatPopup";
import MessageSendingData from "../../components/popups/MessageSendingData";
import {modifyTableData} from '../../helpers/modify-accounts-data';
import filterByProject from "../../helpers/filter-by-project";
import {
    accountDetailsService,
    getActiveYieldkitOffersListService,
    getYieldkitOffersListService
} from "../../services/yieldkit";
import yieldkitOffers from "../../services/yieldkit-offers";
import prepareCSVData from "../../helpers/prepare-csv-data";
import {UPLOADER_TYPE_OFFERS} from "../../constants";
import CustomDoubledCheckbox from "../../components/CustomDoubledCheckbox";

import './yieldkit-offers-list.scss';
import YkOffersBulkLookup from "./YkOffersBulkLookup";

const YieldkitOffersList = ({an_id = 1}) => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const email = localStorage.getItem(USER_EMAIL);
    const role = localStorage.getItem(USER_ROLE);
    const projectName = localStorage.getItem(USER_PROJECT);
    const account = useSelector((state) => state.dataUploader.accountSelected);
    const [accountSelected, setAccountSelected] = useState(null);
    const voluumId = useSelector((state) => state.dataUploader.voluumId);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const isVoluumCSVdifferentColumn = useSelector(state => state.dataUploader.isVoluumCSVdifferentColumn);
    const [isTableLoading, setTableLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingRestData, setLoadingRestData] = useState(false);
    const [isError, setError] = useState(false);
    const [yieldkitOffersListData, setYieldkitOffersListData] = useState([]);
    const [isOffersListActive, setIsOffersListActive] = useState(true);
    const [rowTableData, setRowTableData] = useState([]);
    const [modifiedData, setModifiedData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [voluumCsvData, setVoluumCsvData] = useState([]);
    const [accountsData, setAccountsData] = useState([]);
    const [tableRef, setTableRef] = useState(null);
    const [popupStatus, setPopupStatus] = useState(false);
    const [voluumANId, setVoluumANId] = useState('');
    const [yieldkitAccounts, setYieldkitAccounts] = useState([]);
    const [yieldkitAccount, setYieldkitAccount] = useState({});
    const [isDataSending, setIsDataSending] = useState(false);
    const [dataSendingLoading, setDataSendingLoading] = useState(false);
    const [activeStatus, setActiveStatus] = useState(true);
    const [uniqueParam, setUniqueParam] = useState(true);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        dispatch(accountInformationStart(projectName));
    }, [dispatch, projectName]);

    const getAccountDetails = useCallback(async data => {
        try {
            const accountDetailsData = await accountDetailsService(data);
            const modifyAccountsData = accountDetailsData.data.data.map(account => {
                account.name = account.name.trim();
                return account;
            })
            setRowTableData(modifyAccountsData);

            const modifyData = modifyTableData(accountDetailsData.data.data);

            if (modifyData.length === 1) dispatch(accountSelectedFunc(modifyData[0].name));

            if (role === 'admin') {
                setYieldkitAccounts(modifyData);
                setYieldkitAccount(modifyData[0])
            } else {
                const filteredData = filterByProject(modifyData, projectName);
                setYieldkitAccounts(filteredData);
                setYieldkitAccount(filteredData[0])
            }
        } catch (error) {
            setError(true);
            console.error('Error!', error);
            if (error.response.status === 401) {
                dispatch(logOut());
            }
        }
    }, [rowTableData]);

    const getFirstFTDsData = async (activeStatus) => {
        let copyRestData = [];

        if (activeStatus) {
            copyRestData = await GetActiveYieldkitOffersListData(setLoading, setError, dispatch, setYieldkitOffersListData, setCsvData, an_id, copyRestData, 0, 10)
        } else {
            copyRestData = await GetYieldkitOffersListData(setLoading, setError, dispatch, setYieldkitOffersListData, setCsvData, an_id, copyRestData, 0, 10);
        }
        getRestFtdsData(activeStatus, copyRestData);
    }

    const getRestFtdsData = async (activeStatus, copyRestData) => {
        setLoadingRestData(true);

        try {
            let results;
            if (activeStatus) {
                results = await getActiveYieldkitOffersListService(10, 'ALL', an_id);
            } else {
                results = await getYieldkitOffersListService(10, 'ALL', an_id);
            }

            if (results.data.error) {
                setError(true);
            } else {
                const modifiedData = [...copyRestData, ...results.data.data];
                if(Array.isArray(modifiedData) && modifiedData.length > 0) {
                    setColumns(Object.keys(modifiedData[0]));
                }
                setYieldkitOffersListData(modifiedData);
                setCsvData(prepareCSVData(modifiedData));
            }
            setTimeout(() => {
                setLoadingRestData(false);
            }, 1000);
        } catch (error) {
            setLoadingRestData(false);
            setError(true);
            console.error('Error!', error);
            if (error.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    useEffect(() => {
        if (role === 'admin') {
            getAccountDetails({an_id: an_id});
        } else if (voluumId !== '') {
            getAccountDetails({an_id: an_id, voluum_id: voluumId});
        }
        setIsOffersListActive(true);
        getFirstFTDsData(activeStatus);
    }, [dispatch, pathname, voluumId]);

    useEffect(() => {
        let arr = rowTableData.filter(el => el['status']);
        arr.forEach((el, index, array) => {
            if (account) {
                if (el.name === account) setVoluumANId(el['voluum_affiliate_network_id'])
            } else {
                setVoluumANId(array[0]['voluum_affiliate_network_id'])
            }
        })
        const accParams = sortAccParams(arr, account);
        rewriteTrackingLink(yieldkitOffersListData, setModifiedData, accParams, an_id);
        role !== 'admin' && projectName === '' ? setAccountsData([]) : setAccountsData(arr);
        setTableLoading(false);
        if (account && accountsData.length > 0) {
            let yieldkitAccount = accountsData.find(acc => acc.name === account);
            setAccountSelected(yieldkitAccount);
        }
    }, [yieldkitOffersListData, rowTableData, account, projectName]);

    useEffect(() => {
        setVoluumCsvData(prepareVoluumFormatCSVData(UPLOADER_TYPE_OFFERS, csvData, voluumANId, isVoluumCSVdifferentColumn));
    }, [csvData, modifiedData, voluumANId, isVoluumCSVdifferentColumn]);

    const onDataTableChanged = params => {
        let newCsvData = [];
        let {rowsToDisplay} = params.api.getModel();
        rowsToDisplay.forEach(el => {
            let obj = {...el.data};
            newCsvData.push(obj)
        });
        setCsvData(newCsvData);
        setVoluumCsvData(prepareVoluumFormatCSVData(UPLOADER_TYPE_OFFERS, newCsvData, voluumANId, isVoluumCSVdifferentColumn));
    };

    const clearFilters = () => {
        setActiveStatus(false);
        if (isOffersListActive) removeActiveFilter();
        tableRef.api.setFilterModel(null);
    }

    const openVoluumFilePopup = () => {
        setPopupStatus(true);
    }

    const closePopup = () => {
        setPopupStatus(false);
    }

    const getTableRef = ref => {
        setTableRef(ref);
    }

    const yieldkitAccountChange = ({target}) => {
        setYieldkitAccount(JSON.parse(target.value));
    }

    const getYieldkitOffers = async () => {
        try {
            const {api_key, api_secret, site_id} = yieldkitAccount;
            yieldkitOffers(email, api_key, api_secret, site_id, uniqueParam.toString());
            setIsDataSending(true);
        } catch (error) {
            setError(true);
            console.error('Error!', error);
            if (error.response.status === 401) {
                dispatch(logOut());
            }
        }
    }

    const removeActiveFilter = async () => {
        setIsOffersListActive(false);
        getFirstFTDsData(false);
    }

    const getUniqueParam = e => {
        setUniqueParam(e.target.checked);
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                There were problems receiving data from the server, please try again later.
            </div>
        )
    }

    return (
        <>
            <div
                className={`yieldkit-offers-list-btns-container ${menuStatus ? '' : ' collapse-menu'} ${isLoadingRestData ? 'disabled' : ''}`}>
                <CsvDownload csvData={csvData} filename='yieldkit_offers_list' separator={','}
                             disabled={isLoadingRestData}>
                    Download CSV - all data
                </CsvDownload>
                <OpenPopupBtn openPopup={openVoluumFilePopup} title='Download CSV - Voluum format'
                              disabled={isLoadingRestData}/>
                {/* TODO: after click on "clear all filters" there is infinite loading */}
                <ClearFilters clearFilters={clearFilters} disabled={isLoadingRestData}/>
                {isTableLoading ? <TableLoader/> : null}
                {accountsData.length > 1 && modifiedData.length > 0 ?
                    <div className='yieldkit-offers-list-acc-select'>
                        <CustomOpenSelect data={accountsData} setTableLoading={setTableLoading}
                                          disabled={isLoadingRestData}/>
                    </div> :
                    null
                }
            </div>
            {<YkOffersBulkLookup columns={columns} account={accountSelected} />}
            {
                yieldkitAccounts.length
                    ?
                    <div className={`yieldkit-accounts-btn ${isLoadingRestData ? 'disabled' : ''}`}>
                        <button className={`blue-btn ${isLoadingRestData ? 'disabled' : ''}`}
                                disabled={isLoadingRestData} onClick={getYieldkitOffers}>Get additional yieldkit offers for
                        </button>
                    <select className={`yieldkit-accounts-select ${isLoadingRestData ? 'disabled' : ''}`}
                                name="yieldkit-accounts" id="yieldkit-accounts"
                                onChange={yieldkitAccountChange} disabled={isLoadingRestData}>
                            {yieldkitAccounts.map((account, index) => <option value={JSON.stringify(account)}
                                                                              key={index}>{account.name}</option>)}
                        </select>
                        <CustomDoubledCheckbox insideTextClass={'top-all'} value={uniqueParam} getValue={getUniqueParam} disabled={isLoadingRestData} />

                        {
                            dataSendingLoading
                            &&
                            <div className='sending-data-loading'>
                                <Loader/>
                            </div>
                        }
                    </div> : null
            }


            <div className="pre-yieldkit-offers-table-wrap">
                <PreTableYieldkitOffersListData
                    rowData={modifiedData}
                    onFilterChanged={onDataTableChanged}
                    onSortChanged={onDataTableChanged}
                    getTableRef={getTableRef}
                    filterStatus={isOffersListActive}
                    removeActiveFilter={removeActiveFilter}
                />
                {
                    isLoadingRestData &&
                    <div className='pagination-loading'>
                        <Loader/>
                    </div>
                }
            </div>
            {popupStatus ?
                <CSVinVoluumFormatPopup
                    csvData={voluumCsvData}
                    closePopup={closePopup}
                    from = 'yieldkit'
                /> : null
            }

            {isDataSending && <MessageSendingData
                messageText='The data will be processed from 15 to 20 minutes, after which you will receive it on your email'
                setClosePopup={() => setIsDataSending(false)}
            />
            }
        </>
    )
}

export default YieldkitOffersList;
