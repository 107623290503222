import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Link, useLocation} from "react-router-dom";
import {ADMIN_LINKS} from "../../constants";
import {USER_ROLE} from "../../constants/Auth";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {updateAdminLinks} from "../../helpers/update-admin-links";
import './admin-links.scss';

const AdminLinks = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const role = localStorage.getItem(USER_ROLE);
    const [adminLinks, setAdminLinks] = useState(ADMIN_LINKS);

    useEffect(() => {
        if(role !== 'admin') {
            navigate('/not-found');
        }
    }, [pathname])

    useEffect(() => {
        setAdminLinks(updateAdminLinks(localStorage.getItem("dev-env")));
    }, []);

    const copyAllLinks = () => {
        let text = '';
        adminLinks.forEach(el => text += `${el.linkToCopy}\n`);
        navigator.clipboard.writeText(text);
    }
    return (
        <section>
            <h1>Here you can find a list of links that are available only for user with admin role:</h1>
            <ul className='admin-links-list'>
                {adminLinks.map((el, i) => {
                    if(el.externalType) {
                        return (
                            <li key={i}>
                                <div className="link-icon" title='copy link' onClick={() => {navigator.clipboard.writeText(el.linkToCopy)}}>
                                    <ContentCopyIcon />
                                </div>
                                <a href={el.link} target={el.externalType ? '_blank' : ''}>{el.title}</a>
                            </li>
                        );
                    } else {
                        return (
                            <li key={i}>
                                <div className="link-icon" title='copy link' onClick={() => {navigator.clipboard.writeText(el.linkToCopy)}}>
                                    <ContentCopyIcon />
                                </div>
                                <Link to={el.link}>{el.title}</Link>
                            </li>
                        );
                    }
                })}
                <li className='all-links-item'>
                    <p>Or you can copy all links</p>
                    <div className="link-icon" title='copy link' onClick={copyAllLinks}>
                        <CopyAllIcon />
                    </div>
                </li>
            </ul>
        </section>
    )
}

export default AdminLinks;